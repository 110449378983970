import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Grid,
  Container,
  Card,
  CardContent,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from '@mui/material';
import Footer from 'src/components/Footer';
import { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { backend } from 'src/services/http';
import { renderHttpErrors } from 'src/utils';
import { format } from 'date-fns';
import applications from '../applications';
import { useNavigate, Link } from 'react-router-dom';
import TextBadge from 'src/components/Badge';

const getCandidates = async () => {
  try {
    const res = await backend.get('/candidates');
    return res?.data?.candidates || [];
  } catch (error) {
    renderHttpErrors(error);
  }
};

const renderStage = (stage) => {
  switch (stage) {
    case 'complete':
      return <TextBadge color="success" text="Complete" />;

    case 'draft':
      return <TextBadge color="warning" text="Draft" />;

    case 'notStarted':
      return <TextBadge color="error" text="Not Completed" />;

    case 'correction':
      return <TextBadge color="default" text="Correction" />;
  }
};

const columns = [
  { id: 'firstName', label: 'First Name' },
  { id: 'lastName', label: 'Last Name' },
  { id: 'email', label: 'email' },
  { id: 'applications', label: 'Applications' }
];

function Candidates() {
  const [candidates, setCandidates] = useState([]);

  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredData = candidates.filter((item) =>
    item.firstName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    async function init() {
      const candidates = await getCandidates();
      setCandidates(candidates);
    }

    init();
  }, []);

  return (
    <>
      <Helmet>
        <title>Candidates</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
          // minHeight={'60vh'}
        >
          <Grid item xs={12}>
            {/* <RecentJobs /> */}
            <Card></Card>
            <Box p={2}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <TextField
                  size="small"
                  label="Search"
                  variant="outlined"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ marginBottom: 16 }}
                />
              </Box>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.id}>{column.label}</TableCell>
                      ))}
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => (
                        <TableRow key={row._id}>
                          {columns.map((column) => (
                            <TableCell key={column.id}>
                              {column.id === 'job'
                                ? row[column.id].title
                                : column.id === 'invitationDateTime'
                                ? format(
                                    new Date(row[column.id]),
                                    "dd/MM/yyyy hh:mm aaaaa'm'"
                                  )
                                : column.id === 'stage'
                                ? renderStage(row[column.id])
                                : row[column.id]}
                            </TableCell>
                          ))}
                          <TableCell>
                            {/* <Link to={`/candidate/${row._id}`}> */}
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                            >
                              View
                            </Button>
                            {/* </Link> */}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={candidates.length}
                rowsPerPage={rowsPerPage}
                page={page}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Candidates;
