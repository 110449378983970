import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container, Card, Typography, CardContent } from '@mui/material';
import Footer from 'src/components/Footer';

function UnderDevelopment(props) {
  return (
    <>
      <Helmet>
        <title>Section Under Development</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography mb={4} variant="h4">
                  {props.title}
                </Typography>
                <Typography>
                  "🚧 Whoa, hold on a moment! We're busy building something
                  extraordinary behind the scenes. Our developers are crafting
                  the future of this SAAS wonderland, and it's going to be worth
                  the wait. ⏳✨ While we're putting the final pixels and lines
                  of code together, feel free to grab a metaphorical hard hat
                  and explore the rest of our digital universe. We promise that
                  when we unveil the curtain, you'll be welcomed by a symphony
                  of innovation, beauty, and functionality. Stay tuned, amazing
                  things are coming your way! 🛠️🚀"
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default UnderDevelopment;
