import { backend } from 'src/services/http';
import {
  AUTH_FAIL,
  AUTH_SUCCESS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT
} from './types';
import { toast } from 'react-toastify';

export const login =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      const res = await backend.post('/auth', {
        email,
        password
      });
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.data.accessToken
      });

      dispatch(loadUser());
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL
      });
      const errors = err.response.data.errors;
      errors.forEach((error) => toast(error.msg, { type: 'error' }));
    }
  };

export const loadUser = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('accessToken');
    if (token) {
      backend.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    const res = await backend.get('/auth');
    dispatch({
      type: AUTH_SUCCESS,
      payload: res.data.data
    });
  } catch (err) {
    dispatch({
      type: AUTH_FAIL
    });
  }
};

export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT
  });
};
