import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem
} from '@mui/material';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import Footer from 'src/components/Footer';
import { FieldArray, Form, Formik } from 'formik';
import { createJob, disableJob, updateJob } from 'src/redux/actions/jobs';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

type Props = {
  open;
  setOpen;
  disableJob?;
  jobId;
};

function DeleteJobModal({ open, setOpen, disableJob, jobId }: Props) {
  const handleDisable = async () => {
    alert('Uncommnt the code');
    // const deleted = await disableJob(jobId);
    // if (deleted) {
    //   setOpen(false);
    // }
  };
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Are you sure you want to disable this job?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will stop any new candidates from filling application forms
          related to this job post. Do you want to proceed?
        </DialogContentText>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleDisable}>Yes</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default connect(null, { disableJob })(DeleteJobModal);
