import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Container, Grid, Typography } from '@mui/material';
import Footer from 'src/components/Footer';

import JobsOverview from './JobsOverview';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getJobs } from 'src/redux/actions/jobs';
import { useEffect, useState } from 'react';
import { renderHttpErrors } from 'src/utils';
import { backend } from 'src/services/http';

const getJobsWithApps = async () => {
  try {
    const res = await backend.get('/jobs/apps');
    return res?.data?.jobsWithApps || [];
  } catch (error) {
    renderHttpErrors(error);
  }
};

function Dashboard() {
  const [jobsWithApps, setJobsWithApps] = useState([]);
  useEffect(() => {
    async function init() {
      const jobs = await getJobsWithApps();
      setJobsWithApps(jobs);
      console.log(jobs);
    }

    init();
  }, []);
  return (
    <>
      <Helmet>
        <title>Pulse Care - Dashboard</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
          height={'100%'}
        >
          <Grid item xs={12}>
            {/* <AccountBalance /> */}
            {/* <Typography variant="h1">
              Future place of somthing really cool
            </Typography> */}
          </Grid>
          <Grid item xs={12}>
            {/* <Wallets /> */}
            <JobsOverview jobs={jobsWithApps} />
          </Grid>
          <Grid item lg={4} xs={12}>
            {/* <AccountSecurity /> */}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default Dashboard;
