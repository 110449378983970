import {
  Grid,
  Divider,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  Dialog,
  DialogTitle,
  MenuItem,
  Autocomplete,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import Button from '@mui/material/Button';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  createInvitation,
  getInvitations
} from 'src/redux/actions/invitations';
import { RootState } from 'src/redux/store';

type Props = {
  open;
  setOpen;
  jobs;
};

const validateInvitees = (input: string) => {
  const emailRegex = /^[\w+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  let emails = input.split(',');
  for (let i = 0; i < emails.length; i++) {
    if (!emailRegex.test(emails[i].trim())) {
      return false;
    }
  }

  return true;
};

const ReviewInvite = connect(null, {
  createInvitation,
  getInvitations
})(
  ({
    setReview,
    setInviteModalOpen,
    values,
    createInvitation,
    getInvitations
  }: any) => {
    const emails = values?.invitees?.replace(/\s/g, '').split(',');

    const handleSubmit = async () => {
      const invited = await createInvitation({
        job: values.job,
        invitees: values.invitees,
        title: values.title
      });
      if (invited) {
        // setReview(false);
        setInviteModalOpen(false);
        getInvitations();
      }
    };
    return values ? (
      <Box>
        <DialogTitle>Send an Invite</DialogTitle>
        <DialogContent sx={{ height: '280px' }}>
          <DialogContentText>{values?.job.label}</DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table aria-label="Emails">
                  <TableHead>
                    <TableRow>
                      <TableCell>Invitee(s)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {emails.map((email, idx) => (
                      <TableRow
                        key={idx}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell size="small">{email}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setReview(false)}>
            Previous
          </Button>
          <Button onClick={handleSubmit} type="submit" variant="contained">
            Send Invite
          </Button>
        </DialogActions>
      </Box>
    ) : (
      <></>
    );
  }
);

function NewInviteModal({ open, setOpen, jobs }: Props) {
  const [review, setReview] = useState(false);
  const formRef: any = useRef();
  const jobList = jobs.map((job) => ({ id: job._id, label: job.title }));

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={() => {
        setOpen(false);
        setReview(false);
      }}
    >
      {review ? (
        <ReviewInvite
          setReview={setReview}
          values={formRef?.current?.values}
          setInviteModalOpen={setOpen}
        />
      ) : (
        <Formik
          innerRef={formRef}
          initialValues={{
            title: 'Doctors Recruitment - Fall summer',
            job: '',
            invitees:
              'john.doe@example.com,jane.smith@example.com,mark.johnson@example.com,sarah.wilson@example.com,michael.brown@example.com,emily.davis@example.com,alexander.anderson@example.com,olivia.thomas@example.com,david.martin@example.com,sophia.rodriguez@example.com,robert.walker@example.com,linda.moore@example.com,thomas.hall@example.com,amy.carter@example.com,richard.young@example.com,mary.stewart@example.com,kevin.lee@example.com,jennifer.harris@example.com,matthew.clark@example.com,amanda.miller@example.com'
          }}
          onSubmit={async (values) => {
            console.log(values);
          }}
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form noValidate autoComplete="off">
              <DialogTitle>Send an Invite</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Fields marked with (*) are mandatory and cannot be left blank
                </DialogContentText>
                <Grid container mt={2} spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      label="Title"
                      onChange={handleChange}
                      fullWidth
                      name="title"
                      value={values.title}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      id="job"
                      options={jobList}
                      value={values.job}
                      onChange={(e, value) => {
                        setFieldValue('job', value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          name="job"
                          label="Select a Job"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      multiline
                      rows={4}
                      label="Invitees (Comma-Separated)"
                      onChange={handleChange}
                      fullWidth
                      name="invitees"
                      value={values.invitees}
                    />
                    {/* <Button
                      sx={{ mt: 2 }}
                      size="small"
                      variant="text"
                      component="label"
                    >
                      Or Upload a .CSV
                      <input type="file" hidden />
                    </Button> */}
                  </Grid>
                </Grid>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => setReview(true)}
                  type="submit"
                  variant="contained"
                >
                  Review
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </Dialog>
  );
}

export default connect(
  (state: RootState) => ({ jobs: state.jobsReducer.jobs }),
  {}
)(NewInviteModal);
