import { renderHttpErrors } from 'src/utils';
import { backend } from './http';
import { saveAs } from 'file-saver';

export const downloadFile = async (filePath) => {
  try {
    const res: any = await backend.post(
      '/applications/download-file',
      { filePath },
      { responseType: 'blob' }
    );

    const blob = new Blob([res.data]);
    saveAs(blob, filePath);
  } catch (error) {
    renderHttpErrors(error);
  }
};

export const renderFile = async (filePath) => {
  try {
    const res: any = await backend.post(
      '/applications/download-file',
      { filePath },
      { responseType: 'blob' }
    );

    const blob = new Blob([res.data]);
    return blob;
  } catch (error) {
    renderHttpErrors(error);
  }
};
