import { Box, Typography, colors } from '@mui/material';
import React from 'react';

type Props = {
  color: 'success' | 'error' | 'warning' | 'default';
  text: string;
};

const getColor = (color) => {
  switch (color) {
    case 'success':
      return colors.green[500];

    case 'error':
      return colors.red[600];

    case 'warning':
      return colors.yellow[800];

    case 'default':
      return colors.grey[300];

    default:
      return '#ff6677';
  }
};

const TextBadge = (props: Props) => {
  return (
    <Box p={0.5} bgcolor={getColor(props.color)} borderRadius={1}>
      <Typography fontSize={14} color={'white'}>
        {props.text}
      </Typography>
    </Box>
  );
};

export default TextBadge;
