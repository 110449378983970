import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Grid,
  Container,
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  IconButton,
  Typography,
  Button
} from '@mui/material';
import Footer from 'src/components/Footer';

import RecentJobs from './RecentJobs';
import { connect } from 'react-redux';
import { getJobs } from 'src/redux/actions/jobs';
import { useEffect, useState } from 'react';
import { RootState } from 'src/redux/store';
import {
  ExpandMore,
  ExpandMoreOutlined,
  FavoriteOutlined,
  MoreVertOutlined,
  ShareOutlined
} from '@mui/icons-material';
import { blue, red } from '@mui/material/colors';
import { format } from 'date-fns';
import EditJobModal from './EditJobDialog';
import DeleteJobModal from './DisableJobDialog';

function Jobs({ getJobs, jobs }) {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editDeleteJobId, setEditDeleteJobId] = useState(null);
  const handleEditOrDeleteJob = (id) => {
    setEditDeleteJobId(id);
  };

  useEffect(() => {
    getJobs();
  }, []);
  return (
    <>
      <Helmet>
        <title>Jobs</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          // justifyContent="center"
          // alignItems="stretch"
          spacing={2}
        >
          {/* <Grid item xs={12}>
            <RecentJobs />
          </Grid> */}
          {jobs?.map((job) => {
            return (
              <Grid key={job._id} item xs={12} md={6} lg={4}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
                        {job.title[0]}
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertOutlined />
                      </IconButton>
                    }
                    title={job.title}
                    subheader={format(new Date(job.createdAt), 'dd MMMM yyyy')}
                  />
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical'
                      }}
                    >
                      What is a job description? A job description is a tool
                      that explains the tasks, duties, function and
                      responsibilities of a position. It details who performs a
                      specific type of work, how that work is to be completed,
                      and the frequency and the purpose of the work as it
                      relates to the organization's mission and goals.
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'end' }}>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => {
                        handleEditOrDeleteJob(job._id);
                        setOpenEditModal(true);
                      }}
                    >
                      Edit
                    </Button>
                    {/* <Button
                      size="small"
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        handleEditOrDeleteJob(job._id);
                        setOpenDeleteModal(true);
                      }}
                    >
                      Disable
                    </Button> */}
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
      <Footer />
      <EditJobModal
        open={openEditModal}
        setOpen={setOpenEditModal}
        jobId={editDeleteJobId}
      />

      <DeleteJobModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        jobId={editDeleteJobId}
      />
    </>
  );
}

export default connect(
  (state: RootState) => ({ jobs: state.jobsReducer.jobs }),
  { getJobs }
)(Jobs);
