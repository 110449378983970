import {
  Button,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  Avatar,
  alpha,
  Tooltip,
  CardActionArea,
  styled,
  CircularProgress
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import { getJobs } from 'src/redux/actions/jobs';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
    margin: ${theme.spacing(2, 0, 1, -0.5)};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(1)};
    padding: ${theme.spacing(0.5)};
    border-radius: 60px;
    height: ${theme.spacing(5.5)};
    width: ${theme.spacing(5.5)};
    background: ${
      theme.palette.mode === 'dark'
        ? theme.colors.alpha.trueWhite[30]
        : alpha(theme.colors.alpha.black[100], 0.07)
    };
  
    img {
      background: ${theme.colors.alpha.trueWhite[100]};
      padding: ${theme.spacing(0.5)};
      display: block;
      border-radius: inherit;
      height: ${theme.spacing(4.5)};
      width: ${theme.spacing(4.5)};
    }
`
);

const AvatarAddWrapper = styled(Avatar)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[10]};
        color: ${theme.colors.primary.main};
        width: ${theme.spacing(8)};
        height: ${theme.spacing(8)};
`
);

const CardAddAction = styled(Card)(
  ({ theme }) => `
        border: ${theme.colors.primary.main} dashed 1px;
        height: 100%;
        color: ${theme.colors.primary.main};
        transition: ${theme.transitions.create(['all'])};
        
        .MuiCardActionArea-root {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        
        .MuiTouchRipple-root {
          opacity: .2;
        }
        
        &:hover {
          border-color: ${theme.colors.alpha.black[70]};
        }
`
);

function JobsOverview({ jobs }) {
  const navigate = useNavigate();

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          pb: 3
        }}
      >
        <Typography variant="h3">Jobs</Typography>
        <Button
          size="small"
          variant="outlined"
          startIcon={<AddTwoToneIcon fontSize="small" />}
          onClick={() => navigate('/jobs')}
        >
          Add new job
        </Button>
      </Box>
      {jobs.length ? (
        <Grid container spacing={3}>
          <Grid xs={12} sm={6} md={3} item>
            <Card
              sx={{
                px: 1
              }}
            >
              <CardContent>
                <AvatarWrapper>
                  <img
                    alt="BTC"
                    src="/static/images/placeholders/logo/work.png"
                  />
                </AvatarWrapper>
                <Typography variant="h5" noWrap>
                  Jobs
                </Typography>
                <Typography variant="subtitle1" noWrap>
                  {/*  */}
                </Typography>
                <Box
                  sx={{
                    pt: 3
                  }}
                >
                  <Typography variant="h3" gutterBottom noWrap>
                    {jobs.length}
                  </Typography>
                  <Typography variant="subtitle2" noWrap>
                    Total Jobs
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {jobs?.map((job) => {
            return (
              <Grid xs={12} sm={6} md={3} item>
                <Card
                  sx={{
                    px: 1
                  }}
                  color="primary"
                  raised
                >
                  <CardContent>
                    <AvatarWrapper>
                      <img
                        alt="BTC"
                        src="/static/images/placeholders/logo/work.png"
                      />
                    </AvatarWrapper>
                    <Typography variant="h5" noWrap>
                      {job?.title}
                    </Typography>
                    <Typography variant="subtitle1" noWrap>
                      {job?.overview}
                    </Typography>
                    <Box
                      sx={{
                        pt: 3
                      }}
                    >
                      <Typography variant="h3" gutterBottom noWrap>
                        {job.total}
                      </Typography>
                      <Typography variant="subtitle2" noWrap>
                        Total Applications
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Typography>No Jobs...</Typography>
      )}
    </>
  );
}

export default JobsOverview;
