import {
  JOB_CREATE_SUCCESS,
  JOB_FETCH_SUCCESS,
  JOB_UPDATE_SUCCESS
} from '../actions/types';

const initialState = {
  jobs: [],
  created: false,
  loading: true
};

const jobsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case JOB_CREATE_SUCCESS:
      return {
        ...state,
        jobs: [payload, ...state.jobs],
        created: true
      };

    case JOB_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        jobs: payload
      };

    default:
      return state;
  }
};

export default jobsReducer;
