import { DownloadOutlined, FileDownloadOffOutlined } from '@mui/icons-material';
import { TabPanel } from '@mui/lab';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
  colors
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import Footer from 'src/components/Footer';
import Loading from 'src/components/Loading';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { PrintBreak } from 'src/components/PrintBreak';
import { getApplicationForm } from 'src/redux/actions/applications';
import { RootState } from 'src/redux/store';
import { IApplicationForm } from 'src/types/application';
import { getDateFormatted } from 'src/utils';
import SEQOHS from '../application/SEQOHS';
import { makeStyles } from '@mui/styles';
import PageHeader from './PageHeader';
import { useReactToPrint } from 'react-to-print';

type Props = {
  getApplicationForm: any;
  applicationForm: IApplicationForm;
  loading: boolean;
};

export const previewStyles = makeStyles({
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: 'orange',
      height: 3
    },
    '& .MuiTab-root.Mui-selected': {
      color: colors.blue[400]
    },
    '& .MuiTab-root.Mui-selected:hover': {
      color: '#000'
    },
    '& .MuiButtonBase-root': {
      '-webkit-align-items': 'left !important'
    }
  },
  labelCells: {
    background: '#eee'
  },
  internalTableRow: {
    background: '#57ca22'
  },
  table: {
    border: '2px solid #eee',
    '& tr td': {
      border: '2px solid #eee',
      padding: '8px',
      width: '50%'
    }
  },
  subrow: {
    background: '#fdb8b8'
  }
});

type TableCaptionProps = {
  caption: string;
  actionButton?: any;
};
const TableCaption = ({ caption, actionButton }: TableCaptionProps) => {
  const classes = previewStyles();
  return (
    <TableRow className={classes.internalTableRow}>
      <TableCell colSpan={2}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography fontSize={16} color={'white'}>
            {caption}
          </Typography>
          <Box>{actionButton && actionButton}</Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

const Print = ({ getApplicationForm, applicationForm, loading }: Props) => {
  const params = useParams();
  const applicationId = params.id;

  useEffect(() => {
    getApplicationForm(applicationId);
    document.getElementById('print-button')?.click();
  }, []);

  const classes = previewStyles();

  const {
    application,
    personalInformation,
    employeePreferences,
    availability,
    workingTimeRegulation,
    rightToWork,
    paymentInfo,
    employmentHistory,
    professionalReferences,
    fitnessToWork,
    healthChecklist,
    dbs,
    overseasPoliceCheck,
    declarations,
    equalOpportunityMonitoring,
    nursingCompetencyChecklist,
    documents,
    declarationsAndConsent
  } = applicationForm;

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current
    // bodyClass: 'print-body'
  });

  return !loading ? (
    <>
      <Helmet>
        <title>Application</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader
          title={applicationForm?.application?.job?.title || ''}
          handlePrint={handlePrint}
        />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box
                  ref={printRef}
                  sx={{
                    flexGrow: 1,
                    bgcolor: 'background.paper',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4
                  }}
                >
                  <Box>
                    <Table size="small" className={classes.table}>
                      <TableBody>
                        <TableCaption caption="Personal Information" />
                        <TableRow>
                          <TableCell>Firstname</TableCell>
                          <TableCell>
                            {personalInformation?.firstName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Middlename</TableCell>
                          <TableCell>
                            {personalInformation?.middleName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Lastname</TableCell>
                          <TableCell>{personalInformation?.lastName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Other Names</TableCell>
                          <TableCell>
                            {personalInformation?.otherNames}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Date of Birth:</TableCell>
                          <TableCell>
                            {getDateFormatted(personalInformation?.dob)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Landline Number:</TableCell>
                          <TableCell>{personalInformation?.landline}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Mobile Number:</TableCell>
                          <TableCell>{personalInformation?.mobile}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Email Address:</TableCell>
                          <TableCell>{personalInformation?.email}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Current Address:</TableCell>
                          <TableCell>
                            {personalInformation?.currentAddress}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Post Code:</TableCell>
                          <TableCell>{personalInformation?.postCode}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Other Address:</TableCell>
                          <TableCell>
                            {personalInformation?.otherAddress}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Post Code:</TableCell>
                          <TableCell>
                            {personalInformation?.otherAddressPostCode}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Next of Kin Name:</TableCell>
                          <TableCell>{personalInformation?.kin_name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Relationship:</TableCell>
                          <TableCell>
                            {personalInformation?.kin_relationship}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Mobile Number:</TableCell>
                          <TableCell>
                            {personalInformation?.kin_mobile}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Alternate Number:</TableCell>
                          <TableCell>
                            {personalInformation?.kin_alternateNumber}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Address:</TableCell>
                          <TableCell>
                            {personalInformation?.kin_address}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Post Code:</TableCell>
                          <TableCell>
                            {personalInformation?.kin_postCode}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>How did you hear about us?</TableCell>
                          <TableCell>
                            {personalInformation?.howDidYouHearAboutUs}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>

                  <Box>
                    <Table size="small" className={classes.table}>
                      <TableBody>
                        <TableCaption caption="Employee Preferences" />
                        <TableRow>
                          <TableCell>Current Job Title</TableCell>
                          <TableCell>
                            {employeePreferences?.currentJob}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Current Salary</TableCell>
                          <TableCell>
                            {employeePreferences?.currentSalary}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Preferred Work Location</TableCell>
                          <TableCell>
                            {employeePreferences?.prefferedLocation}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            What is your maximum travel distance
                          </TableCell>
                          <TableCell>
                            {
                              employeePreferences?.distanceWillingToTravelInMiles
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Skills & Trainings</TableCell>
                          <TableCell>
                            {employeePreferences?.skillsAndTrainings?.map(
                              (skill) => skill + ', '
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Do you hold a valid driving licence?
                          </TableCell>
                          <TableCell>
                            {employeePreferences?.drivingLicense}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Personal Indemnity Cover</TableCell>
                          <TableCell>
                            {employeePreferences?.personalIndemnityCover}
                          </TableCell>
                        </TableRow>

                        <TableCaption caption="Availability" />

                        <TableRow>
                          <TableCell>
                            When you will be available for joining?
                          </TableCell>
                          <TableCell>
                            {getDateFormatted(availability?.joiningDate)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            When would you be available for interview?
                          </TableCell>
                          <TableCell>
                            {getDateFormatted(
                              employeePreferences?.interviewDate
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Available for both day and night shifts
                          </TableCell>
                          <TableCell>
                            {employeePreferences?.availableForDayNightShifts}
                          </TableCell>
                        </TableRow>

                        <TableCaption caption="Working-time Regulations" />
                        <TableRow>
                          <TableCell>
                            Are you willing to work for more than an average 48
                            hours a week?
                          </TableCell>
                          <TableCell>
                            {workingTimeRegulation?.workForMoreThanAnAverage}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            When would you be available for interview?
                          </TableCell>
                          <TableCell>
                            {getDateFormatted(
                              employeePreferences?.interviewDate
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Available for both day and night shifts
                          </TableCell>
                          <TableCell>
                            {employeePreferences?.availableForDayNightShifts}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>

                  <Box>
                    <Table size="small" className={classes.table}>
                      <TableBody>
                        <TableCaption caption="Right to Work" />
                        <TableRow>
                          <TableCell>Nationality</TableCell>
                          <TableCell>{rightToWork?.nationality}</TableCell>
                        </TableRow>
                        {rightToWork?.nationality !== 'British' && (
                          <>
                            <TableRow>
                              <TableCell>Country of Citizenship</TableCell>
                              <TableCell>
                                {rightToWork?.countryOfCitizenship}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Visa Type</TableCell>
                              <TableCell>{rightToWork?.visaType}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Visa Expiry</TableCell>
                              <TableCell>{rightToWork?.visaExpiry}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Visa Restrictions (Please specify)
                              </TableCell>
                              <TableCell>
                                {rightToWork?.visaRestrictions}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Visa/Indefinte Leave To Remain In Current
                                Passport
                              </TableCell>
                              <TableCell>
                                {
                                  rightToWork?.visaIndefinteLeaveToRemainInCurrentPassport
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Biometric Card</TableCell>
                              <TableCell>
                                {rightToWork?.biometricCard}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Biometric Card Expiry</TableCell>
                              <TableCell>
                                {rightToWork?.biometricCardExpiry}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Please Provide Pulse Care With Your Unique
                                Online Share Code
                              </TableCell>
                              <TableCell>{rightToWork?.shareCode}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Do you Give Pulse Care Consent to an Identity
                                and Right To Work Check?
                              </TableCell>
                              <TableCell>
                                {
                                  rightToWork?.consentToIdentityAndRightToWorkCheck
                                }
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </Box>

                  <Box>
                    <Table size="small" className={classes.table}>
                      <TableBody>
                        <TableCaption caption="Payment Information" />
                        <TableRow>
                          <TableCell>Personal Account Number</TableCell>
                          <TableCell>{paymentInfo?.accountNumber}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Banking Name</TableCell>
                          <TableCell>{paymentInfo?.bankName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Sort Code</TableCell>
                          <TableCell>{paymentInfo?.sortCode}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>

                  <Box>
                    <Table size="small" className={classes.table}>
                      <TableBody>
                        <TableCaption caption="Employment History" />
                        {employmentHistory?.employments?.map(
                          (employment, idx) => (
                            <React.Fragment key={idx}>
                              <TableRow>
                                <TableCell>Job Title</TableCell>
                                <TableCell>{employment?.jobTitle}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Band / Grade</TableCell>
                                <TableCell>{employment?.bandGrade}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Date From</TableCell>
                                <TableCell>
                                  {getDateFormatted(employment?.dateFrom)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Date To</TableCell>
                                <TableCell>
                                  {getDateFormatted(employment?.dateTo)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Place of Work</TableCell>
                                <TableCell>{employment?.placeOfWork}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Company Name</TableCell>
                                <TableCell>{employment?.companyName}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Salary</TableCell>
                                <TableCell>{employment?.salary}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  Reason for leaving the job
                                </TableCell>
                                <TableCell>
                                  {employment?.reasonForLeaving}
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          )
                        )}
                      </TableBody>
                    </Table>
                    <Table size="small" className={classes.table}>
                      <TableBody>
                        <TableCaption caption="Professional References" />
                        {professionalReferences?.references?.map(
                          (reference, index) => (
                            <React.Fragment key={index}>
                              <TableRow className={classes.subrow}>
                                <TableCell>#{index + 1} Reference</TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Referee Email</TableCell>
                                <TableCell>{reference?.email}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Working Start Date</TableCell>
                                <TableCell>
                                  {getDateFormatted(reference?.dateFrom)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Working End Date</TableCell>
                                <TableCell>
                                  {getDateFormatted(reference?.dateTo)}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Referee Title</TableCell>
                                <TableCell>{reference?.title}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Referee Fullname</TableCell>
                                <TableCell>{reference?.fullName}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Referee Job Title</TableCell>
                                <TableCell>{reference?.jobTitle}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Referee Department</TableCell>
                                <TableCell>{reference?.department}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Referee Company Name</TableCell>
                                <TableCell>{reference?.company}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Referee Address Line 1</TableCell>
                                <TableCell>{reference?.address1}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Referee Address Line 2</TableCell>
                                <TableCell>{reference?.address2}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Referee City</TableCell>
                                <TableCell>{reference?.city}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Referee Post Code</TableCell>
                                <TableCell>{reference?.postCode}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Referee Telephone</TableCell>
                                <TableCell>{reference?.telephone}</TableCell>
                              </TableRow>
                            </React.Fragment>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </Box>

                  <Box>
                    <Table size="small" className={classes.table}>
                      <TableBody>
                        <TableCaption caption="Fitness to Work" />
                        <TableRow>
                          <TableCell>
                            I will ensure that I am healthy and fit to work
                            shifts offered to me and give my consent to Pulse
                            Care releasing my health and immunisation records
                            for review to Qualified Occupational Health Adviser.
                            I understand that based on this review I may be
                            required to undergo a medical examination to
                            establish my fitness for work. I also hereby consent
                            to Pulse Care Limited obtaining further information
                            regarding my health from my GP or Occupational
                            Health Department.
                          </TableCell>
                          <TableCell>{fitnessToWork?.fitToWork}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Have you recieved Influenza Vaccine in the last 12
                            months?
                          </TableCell>
                          <TableCell>
                            {fitnessToWork?.receivedInfluenzaVaccine}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Have you recieved both doses of Covid-19 Vaccine?
                          </TableCell>
                          <TableCell>
                            {fitnessToWork?.receivedCovid19Vaccine}
                          </TableCell>
                        </TableRow>
                        {fitnessToWork?.receivedCovid19Vaccine === 'Yes' && (
                          <>
                            <TableRow>
                              <TableCell>
                                Date of Covid 19 Vaccine first dose
                              </TableCell>
                              <TableCell>
                                {fitnessToWork?.covid19VaccinationDoseOneDate &&
                                  getDateFormatted(
                                    fitnessToWork?.covid19VaccinationDoseOneDate
                                  )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Date of Covid 19 Vaccine second dose
                              </TableCell>
                              <TableCell>
                                {fitnessToWork?.covid19VaccinationDoseOTwoDate &&
                                  getDateFormatted(
                                    fitnessToWork?.covid19VaccinationDoseOTwoDate
                                  )}
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </Box>

                  <PrintBreak />
                  <Box>
                    {/* Health Checklist */}
                    <SEQOHS data={healthChecklist} />
                  </Box>
                  <PrintBreak />

                  <Box>
                    <Table size="small" className={classes.table}>
                      <TableBody>
                        {/* DBS Start */}
                        <TableCaption caption="DBS" />
                        {dbs?.existingDbs?.holdACurrentDbsCheck === 'Yes' && (
                          <>
                            <TableRow className={classes.subrow}>
                              <TableCell colSpan={2}>
                                Existing / Disclosure and Barring Service (DBS)
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Do you hold a current DBS check required for the
                                role applied for?
                              </TableCell>
                              <TableCell>
                                {dbs?.existingDbs?.holdACurrentDbsCheck}
                              </TableCell>
                            </TableRow>
                            {dbs?.existingDbs?.holdACurrentDbsCheck ===
                              'Yes' && (
                              <>
                                <TableRow>
                                  <TableCell>
                                    Have You Registered Your DBS With Update
                                    Service?
                                  </TableCell>
                                  <TableCell>
                                    {
                                      dbs?.existingDbs
                                        ?.registeredYourDbsWithUpdateService
                                    }
                                  </TableCell>
                                </TableRow>
                                {dbs?.existingDbs
                                  ?.registeredYourDbsWithUpdateService ===
                                  'Yes' && (
                                  <TableRow>
                                    <TableCell>
                                      Do you give permission to check your DBS
                                      certificate via the update Service?
                                    </TableCell>
                                    <TableCell>
                                      {
                                        dbs?.existingDbs
                                          ?.doYouGivePermissionToCheckDbsCertificate
                                      }
                                    </TableCell>
                                  </TableRow>
                                )}
                                <TableRow>
                                  <TableCell>
                                    Please enter 12 digit number mentioned on
                                    your dbs
                                  </TableCell>
                                  <TableCell>
                                    {dbs?.existingDbs?.disclosureNumber}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Disclosure Expiration</TableCell>
                                  <TableCell>
                                    {dbs?.existingDbs?.disclosureExpiration}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    Do you hold a current Disclosure Scotland
                                    (PVG) check required for the role applied
                                    for?
                                  </TableCell>
                                  <TableCell>
                                    {
                                      dbs?.existingDbs
                                        ?.holdCurrentDisclosureScotland
                                    }
                                  </TableCell>
                                </TableRow>
                                {dbs?.existingDbs
                                  ?.holdCurrentDisclosureScotland === 'Yes' && (
                                  <>
                                    <TableRow>
                                      <TableCell>
                                        PVG Disclosure Number
                                      </TableCell>
                                      <TableCell>
                                        {dbs?.existingDbs?.disclosureNumber}
                                      </TableCell>
                                    </TableRow>

                                    <TableRow>
                                      <TableCell>
                                        PVG Disclosure Expiration
                                      </TableCell>
                                      <TableCell>
                                        {
                                          dbs?.existingDbs
                                            ?.disclosureExpirationScotland
                                        }
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                        {dbs?.existingDbs?.holdACurrentDbsCheck === 'No' && (
                          <>
                            <TableRow className={classes.subrow}>
                              <TableCell colSpan={2}>
                                New / Disclosure and Barring Service (DBS)
                              </TableCell>
                            </TableRow>
                            <TableRow className={classes.subrow}>
                              <TableCell colSpan={2}>
                                Personal Information
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Title</TableCell>
                              <TableCell>{dbs?.title}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Current Forename</TableCell>
                              <TableCell>{dbs?.currentForeName}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Current Middlename</TableCell>
                              <TableCell>{dbs?.currtentMiddleName}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Current Surname</TableCell>
                              <TableCell>{dbs?.currentSurName}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Gender</TableCell>
                              <TableCell>{dbs?.gender}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Have you ever used any other forenames
                              </TableCell>
                              <TableCell>
                                {dbs?.everUsedAnyForeNamesBefore}
                              </TableCell>
                            </TableRow>
                            {dbs?.everUsedAnyForeNamesBefore === 'Yes' && (
                              <TableRow>
                                <TableCell>Previous forenames</TableCell>
                                <TableCell>{dbs?.otherForenames}</TableCell>
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell>
                                Have you ever used any other surnames
                              </TableCell>
                              <TableCell>
                                {dbs?.everUsedAnySurNamesBefore}
                              </TableCell>
                            </TableRow>
                            {dbs?.everUsedAnySurNamesBefore === 'Yes' && (
                              <TableRow>
                                <TableCell>Previous forenames</TableCell>
                                <TableCell>{dbs?.otherSurNames}</TableCell>
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell>
                                Have you ever changed your surnames since birth?
                              </TableCell>
                              <TableCell>
                                {dbs?.changedSurNameSinceBirth}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Mothers maiden name</TableCell>
                              <TableCell>{dbs?.mothersMaidenName}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Nationality at Birth</TableCell>
                              <TableCell>{dbs?.nationalityAtBirth}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Have your ever changed your nationality since
                                birth?
                              </TableCell>
                              <TableCell>
                                {dbs?.changedNationalitySinceBirth}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Do you have any convictions, cautions,
                                reprimands or final warnings which would not be
                                filtered in line with current guidance
                              </TableCell>
                              <TableCell>
                                {dbs?.doYouHaveAnyConvictions}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                I consent to Pulse Care using the information
                                provided to applyfor a DBS on my behalf
                              </TableCell>
                              <TableCell>{dbs?.consentToApplyForDbs}</TableCell>
                            </TableRow>
                            <TableRow className={classes.subrow}>
                              <TableCell colSpan={2}>Current Address</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Date Moved In</TableCell>
                              <TableCell>
                                {dbs?.currentAddress?.dateMovedIn &&
                                  getDateFormatted(
                                    dbs?.currentAddress?.dateMovedIn
                                  )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Address Line 1</TableCell>
                              <TableCell>
                                {dbs?.currentAddress?.addressLine1}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Address Line 2</TableCell>
                              <TableCell>
                                {dbs?.currentAddress?.addressLine2}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>City / Town</TableCell>
                              <TableCell>
                                {dbs?.currentAddress?.addressLine2}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Country</TableCell>
                              <TableCell>
                                {dbs?.currentAddress?.country}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Post Code</TableCell>
                              <TableCell>
                                {dbs?.currentAddress?.postCode}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Changed Address</TableCell>
                              <TableCell>{dbs?.changedAddress}</TableCell>
                            </TableRow>
                            {dbs?.changedAddress === 'Yes' && (
                              <>
                                {dbs?.previousAddress.map((address, index) => {
                                  return (
                                    <>
                                      <TableRow className={classes.subrow}>
                                        <TableCell colSpan={2}>
                                          - Previous Address - #{index + 1}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Date Moved In</TableCell>
                                        <TableCell>
                                          {address?.dateMovedIn &&
                                            getDateFormatted(
                                              address?.dateMovedIn
                                            )}
                                        </TableCell>
                                      </TableRow>

                                      <TableRow>
                                        <TableCell>Date Moved Out</TableCell>
                                        <TableCell>
                                          {address?.dateMovedOut &&
                                            getDateFormatted(
                                              address?.dateMovedOut
                                            )}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Address Line 1</TableCell>
                                        <TableCell>
                                          {address?.addressLine1}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Address Line 2</TableCell>
                                        <TableCell>
                                          {address?.addressLine2}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>City / Town</TableCell>
                                        <TableCell>
                                          {address?.cityTown}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Country</TableCell>
                                        <TableCell>
                                          {address?.country}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>Post Code</TableCell>
                                        <TableCell>
                                          {address?.postCode}
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  );
                                })}
                              </>
                            )}
                            <TableRow className={classes.subrow}>
                              <TableCell colSpan={2}>&nbsp;</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Have you lived outside the UK for more than 6
                                months in the past 5 YEARS?
                              </TableCell>
                              <TableCell>{dbs?.everLivedOutsideUK}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Do you have any convictions, cautions,
                                reprimands or final warnings which would not be
                                filtered in line with current guidance?
                              </TableCell>
                              <TableCell>{dbs?.anyConvictions}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                I have read and understood the above
                                information, and consent to the submission of
                                the application
                              </TableCell>
                              <TableCell>{dbs?.consent}</TableCell>
                            </TableRow>
                          </>
                        )}
                        {/* DBS End */}

                        {/* Overseas Police Check Start */}
                        <TableCaption caption="Overseas Police Check" />
                        <TableRow>
                          <TableCell>
                            Have you ever been outside of the UK over six months
                            in the past five years?
                          </TableCell>
                          <TableCell>
                            {overseasPoliceCheck?.everLivedOutsideUK}
                          </TableCell>
                        </TableRow>
                        {overseasPoliceCheck?.everLivedOutsideUK === 'Yes' &&
                          overseasPoliceCheck?.locations?.map(
                            (location, idx) => {
                              return (
                                <>
                                  <TableRow className={classes.subrow}>
                                    <TableCell colSpan={2}>
                                      - Location #{idx + 1}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Country</TableCell>
                                    <TableCell>{location?.location}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Date From</TableCell>
                                    <TableCell>
                                      {location?.from &&
                                        getDateFormatted(location?.from)}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Date To</TableCell>
                                    <TableCell>
                                      {location?.to &&
                                        getDateFormatted(location?.to)}
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            }
                          )}
                        {/* Overseas Police Check End */}

                        {/* Declarations Start */}
                        <TableCaption caption="Declaration" />
                        <TableRow>
                          <TableCell>
                            Do you have any convictions, cautions, reprimands,
                            warnings or additional information that are not
                            'protected' as deﬁned by the rehabilitation of
                            oﬀenders act 1974 (exceptions) order 1975 (2013) on
                            your current DBS, update service or disclosure
                            Scotland?
                          </TableCell>
                          <TableCell>{declarations?.anyConvictions}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Are you aware of any previous, current or pending
                            investigations, police enquiries or legal
                            proceedings following allegations made against you
                            in the UK or any other country?)
                          </TableCell>
                          <TableCell>
                            {declarations?.awareOfPreviousInvestigations}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            For basic check (non-patient care facing) do you
                            have any unspent convictions?
                          </TableCell>
                          <TableCell>
                            {declarations?.unspentConvictions}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Disclosure Scotland only (PVG scheme - disclosure
                            Scotland) do you have any spent or unspent
                            convictions?
                          </TableCell>
                          <TableCell>
                            {declarations?.disclosureScotland}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Are you aware if you ever had any proceedings
                            brought against you in work or personal life, in the
                            UK or any other country that could be considered a
                            risk to safeguarding or children or vulnerable
                            adults?
                          </TableCell>
                          <TableCell>
                            {declarations?.awareOfProceedings}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Are you aware of any current investigations by any
                            employer/organisation or professional regulatory
                            body in the UK or any other country?
                          </TableCell>
                          <TableCell>
                            {declarations?.awareOfCurrentInvestigations}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Have you been suspended/terminated/had restrictions
                            placed on your practice from
                            employment/organisations or professional regulatory
                            body(s) in the UK or any other country?
                          </TableCell>
                          <TableCell>{declarations?.everSuspended}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Have You Any Pending Or Previous ﬁnancial Or Civil
                            Actions Brought Against You?
                          </TableCell>
                          <TableCell>
                            {declarations?.anyPendingActionsAgainstYou}
                          </TableCell>
                        </TableRow>
                        {/* Declarations End */}
                      </TableBody>
                    </Table>
                  </Box>

                  <Box>
                    <Table size="small" className={classes.table}>
                      <TableBody>
                        <TableCaption caption="Equal Opportunity Monitoring" />
                        <TableRow>
                          <TableCell>Gender</TableCell>
                          <TableCell>
                            {equalOpportunityMonitoring?.gender}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Do you consider yourself to be a disabled person?
                          </TableCell>
                          <TableCell>
                            {equalOpportunityMonitoring?.disable}
                          </TableCell>
                        </TableRow>
                        {equalOpportunityMonitoring?.disable === 'Yes' && (
                          <TableRow>
                            <TableCell>
                              Please give brief details about your disability
                            </TableCell>
                            <TableCell>
                              {equalOpportunityMonitoring?.disability}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell>Sexual Orientation</TableCell>
                          <TableCell>
                            {equalOpportunityMonitoring?.sexualOrientation}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Religion or Belief</TableCell>
                          <TableCell>
                            {equalOpportunityMonitoring?.religion}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Ethnic Origin</TableCell>
                          <TableCell>
                            {equalOpportunityMonitoring?.ethnicOrigin}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Nationality</TableCell>
                          <TableCell>
                            {equalOpportunityMonitoring?.nationality}
                          </TableCell>
                        </TableRow>

                        {/* Nursing Competency Checklist */}
                        <TableCaption caption="Nursing Competency Checklist" />
                        <TableRow>
                          <TableCell>Competency and Accountability</TableCell>
                          <TableCell>
                            {nursingCompetencyChecklist?.competencies &&
                              nursingCompetencyChecklist?.competencies.join(
                                ', '
                              )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Experienced In</TableCell>
                          <TableCell>
                            {nursingCompetencyChecklist?.experiences &&
                              nursingCompetencyChecklist?.experiences.join(
                                ', '
                              )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>

                  <Box>
                    <Table size="small" className={classes.table}>
                      <TableBody>
                        <TableCaption caption="Documents" />
                        <TableRow>
                          <TableCell>Passport</TableCell>
                          <TableCell align="center">
                            {documents?.passport ? (
                              'Uploaded'
                            ) : (
                              <Typography
                                justifyContent={'center'}
                                gap={1}
                                display={'flex'}
                                alignItems={'center'}
                                color={'error'}
                              >
                                Not Uploaded{' '}
                                <FileDownloadOffOutlined color="error" />
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>BRP Front</TableCell>
                          <TableCell align="center">
                            {documents?.brp ? (
                              'Uploaded'
                            ) : (
                              <Typography
                                justifyContent={'center'}
                                gap={1}
                                display={'flex'}
                                alignItems={'center'}
                                color={'error'}
                              >
                                Not Uploaded{' '}
                                <FileDownloadOffOutlined color="error" />
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>BRP Back</TableCell>
                          <TableCell align="center">
                            {documents?.brpBack ? (
                              'Uploaded'
                            ) : (
                              <Typography
                                justifyContent={'center'}
                                gap={1}
                                display={'flex'}
                                alignItems={'center'}
                                color={'error'}
                              >
                                Not Uploaded{' '}
                                <FileDownloadOffOutlined color="error" />
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>
                            Proof of ID Front Side: example UK Driving license
                          </TableCell>
                          <TableCell align="center">
                            {documents?.proofOfId ? (
                              'Uploaded'
                            ) : (
                              <Typography
                                justifyContent={'center'}
                                gap={1}
                                display={'flex'}
                                alignItems={'center'}
                                color={'error'}
                              >
                                Not Uploaded{' '}
                                <FileDownloadOffOutlined color="error" />
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Proof of ID Back Side: example UK Driving license
                          </TableCell>
                          <TableCell align="center">
                            {documents?.proofOfIdBack ? (
                              'Uploaded'
                            ) : (
                              <Typography
                                justifyContent={'center'}
                                gap={1}
                                display={'flex'}
                                alignItems={'center'}
                                color={'error'}
                              >
                                Not Uploaded{' '}
                                <FileDownloadOffOutlined color="error" />
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Proof of Address 1</TableCell>
                          <TableCell align="center">
                            {documents?.proofOfAddress1 ? (
                              'Uploaded'
                            ) : (
                              <Typography
                                justifyContent={'center'}
                                gap={1}
                                display={'flex'}
                                alignItems={'center'}
                                color={'error'}
                              >
                                Not Uploaded{' '}
                                <FileDownloadOffOutlined color="error" />
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Proof of Address 2</TableCell>
                          <TableCell align="center">
                            {documents?.proofOfAddress2 ? (
                              'Uploaded'
                            ) : (
                              <Typography
                                justifyContent={'center'}
                                gap={1}
                                display={'flex'}
                                alignItems={'center'}
                                color={'error'}
                              >
                                Not Uploaded{' '}
                                <FileDownloadOffOutlined color="error" />
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>CV / Resume</TableCell>
                          <TableCell align="center">
                            {documents?.cvResume ? (
                              'Uploaded'
                            ) : (
                              <Typography
                                justifyContent={'center'}
                                gap={1}
                                display={'flex'}
                                alignItems={'center'}
                                color={'error'}
                              >
                                Not Uploaded{' '}
                                <FileDownloadOffOutlined color="error" />
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            IV administration certificate ( Applicable to nurses
                            only).
                          </TableCell>
                          <TableCell align="center">
                            {documents?.ivAdminCertificate ? (
                              'Uploaded'
                            ) : (
                              <Typography
                                justifyContent={'center'}
                                gap={1}
                                display={'flex'}
                                alignItems={'center'}
                                color={'error'}
                              >
                                Not Uploaded{' '}
                                <FileDownloadOffOutlined color="error" />
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>NMC statement( For nurses only)</TableCell>
                          <TableCell align="center">
                            {documents?.nmcStatement ? (
                              'Uploaded'
                            ) : (
                              <Typography
                                justifyContent={'center'}
                                gap={1}
                                display={'flex'}
                                alignItems={'center'}
                                color={'error'}
                              >
                                Not Uploaded{' '}
                                <FileDownloadOffOutlined color="error" />
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Trainings Certificate.</TableCell>
                          <TableCell align="center">
                            {documents?.trainingCertificate ? (
                              'Uploaded'
                            ) : (
                              <Typography
                                justifyContent={'center'}
                                gap={1}
                                display={'flex'}
                                alignItems={'center'}
                                color={'error'}
                              >
                                Not Uploaded{' '}
                                <FileDownloadOffOutlined color="error" />
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>OLD DBS Copy</TableCell>
                          <TableCell align="center">
                            {documents?.oldDbsCopy ? (
                              'Uploaded'
                            ) : (
                              <Typography
                                justifyContent={'center'}
                                gap={1}
                                display={'flex'}
                                alignItems={'center'}
                                color={'error'}
                              >
                                Not Uploaded{' '}
                                <FileDownloadOffOutlined color="error" />
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Immunisation history from the GP
                          </TableCell>
                          <TableCell align="center">
                            {documents?.immunizationHistory ? (
                              'Uploaded'
                            ) : (
                              <Typography
                                justifyContent={'center'}
                                gap={1}
                                display={'flex'}
                                alignItems={'center'}
                                color={'error'}
                              >
                                Not Uploaded{' '}
                                <FileDownloadOffOutlined color="error" />
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Qualifications: example, degree certificate, NVQ
                            certificate, or any other certification
                          </TableCell>
                          <TableCell align="center">
                            {documents?.qualificationCertificate ? (
                              'Uploaded'
                            ) : (
                              <Typography
                                justifyContent={'center'}
                                gap={1}
                                display={'flex'}
                                alignItems={'center'}
                                color={'error'}
                              >
                                Not Uploaded{' '}
                                <FileDownloadOffOutlined color="error" />
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Scan or photo of your National Insurance card/ P45/
                            P60/ HMRC letter/ DWP letter as proof of your
                            National Insurance number
                          </TableCell>
                          <TableCell align="center">
                            {documents?.nimProof ? (
                              'Uploaded'
                            ) : (
                              <Typography
                                justifyContent={'center'}
                                gap={1}
                                display={'flex'}
                                alignItems={'center'}
                                color={'error'}
                              >
                                Not Uploaded{' '}
                                <FileDownloadOffOutlined color="error" />
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            Colour passport size photo for your ID badge
                          </TableCell>
                          <TableCell align="center">
                            {documents?.idBadge ? (
                              'Uploaded'
                            ) : (
                              <Typography
                                justifyContent={'center'}
                                gap={1}
                                display={'flex'}
                                alignItems={'center'}
                                color={'error'}
                              >
                                Not Uploaded{' '}
                                <FileDownloadOffOutlined color="error" />
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>

                  <Box>
                    <Table size="small" className={classes.table}>
                      <TableBody>
                        <TableCaption caption="Employee Declaration" />
                        <TableRow>
                          <TableCell>
                            I understand that during the course of my engagement
                            with Pulse Care Limited, my compliance and
                            documentation may require updating form time to
                            time.
                          </TableCell>
                          <TableCell>
                            {declarationsAndConsent?.agreeToDocumentationChange}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I agree to comply with these requirements as
                            necessary and within the timelines given to prevent
                            delays on work being offered. If any of your
                            compliance items lapse, it may cause the
                            suspension/and or termination of your placement.
                          </TableCell>
                          <TableCell>
                            {
                              declarationsAndConsent?.agreeToComplyWithRequirements
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I have received, read and understood a copy of the
                            Staff Handbook. I have received the agency's AWR
                            policy. I have read and understood the terms.
                          </TableCell>
                          <TableCell>
                            {declarationsAndConsent?.agreeToStaffHandbook}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I consent Pulse Care Limited completing my monthly
                            PIN/GMC checks, my training record checks and my
                            qualification checks
                          </TableCell>
                          <TableCell>
                            {declarationsAndConsent?.agreeToMonthlyPinChecks}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I understand all the policies and procedures and
                            will abide by the code of conduct at all times.
                          </TableCell>
                          <TableCell>
                            {
                              declarationsAndConsent?.agreeToAbideByTheCodeOfConduct
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            In line with the nature of the work I will be
                            undertaking, I understand that the documentation
                            including Disclosure And Barring Service (DBS) I
                            have given in line with compliance procedures, may
                            be audited even by third party auditors in relation
                            to the provision of the services at any time and I
                            consent to this.
                          </TableCell>
                          <TableCell>
                            {
                              declarationsAndConsent?.agreeToAuditDBSByThirdParty
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I Declare That All Information Provided Within This
                            Registration Form For The Application Of Employment
                            With Pulse Care, Are True To The Best Of My
                            Knowledge. I Agree To Comply With All The
                            Requirements And Declarations Provided To Me By
                            Pulse Care Listed In The T&C's And Throughout The
                            Registration. If There Are Any Changes To The
                            Information I Provide, I Will Notify Pulse Care
                            Immediately.
                          </TableCell>
                          <TableCell>
                            {
                              declarationsAndConsent?.informationProvidedisCorrect
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I consent to Pulse care using my personel
                            information for work, compliances, training and
                            payroll emails and calls
                          </TableCell>
                          <TableCell>
                            {
                              declarationsAndConsent?.agreeToUseMyPersonalInformation
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I consent to send my personel information to our
                            approved Umbrella companies when not paid via PAYE.
                          </TableCell>
                          <TableCell>
                            {
                              declarationsAndConsent?.agreeToSendMyInfoToUmbrellaCompanies
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I consent Pulse Care to save a hard copy of my DBS.
                          </TableCell>
                          <TableCell>
                            {declarationsAndConsent?.consentToSaveHardCopyOfDBS}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I consent Pulse Care to submit my annual DBS
                            application.
                          </TableCell>
                          <TableCell>
                            {declarationsAndConsent?.consentToSubmitMyAnnualDBS}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I consent to bear the cost associated with the
                            renewal of the (DBS) check, in accordance with the
                            rates charged by the DBS service provider.
                          </TableCell>
                          <TableCell>
                            {
                              declarationsAndConsent?.consentToBearTheCostOfDbsRenewal
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I consent Pulse Care to check with the Home Office
                            to confirm my employment eligibility when needed.
                          </TableCell>
                          <TableCell>
                            {
                              declarationsAndConsent?.consentToCheckWithHomeOfficeForEligibility
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I consent Pulse Care to check my training
                            certifications with external certificate issuers.
                          </TableCell>
                          <TableCell>
                            {
                              declarationsAndConsent?.consentToCheckMyTrainingCertifications
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I consent Pulse Care to send my personal information
                            to hospitals, care homes, trusts, etc.
                          </TableCell>
                          <TableCell>
                            {
                              declarationsAndConsent?.consentToSendMyInfoToHospitals
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I consent Pulse Care to send my personal information
                            to attendance app.
                          </TableCell>
                          <TableCell>
                            {
                              declarationsAndConsent?.consentToSendMyInfoToAttendanceApp
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I consent Pulse Care to record my online video
                            interview for audit purposes.
                          </TableCell>
                          <TableCell>
                            {
                              declarationsAndConsent?.consentToRecordMyVideoInterview
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I consent that approved third-party attendance
                            application may be used to track my regular
                            attendance.
                          </TableCell>
                          <TableCell>
                            {
                              declarationsAndConsent?.consentToUsageOfThirdPartyAttendanceApp
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I consent to take the financial responsibility for
                            the payment of £52 associated with my (DBS) check.
                            This payment may be made either in advance or
                            through the reimbursement of my salary, as deemed
                            appropriate by the company. This is exempted, if the
                            DBS(Enhanced) is already on update service.
                          </TableCell>
                          <TableCell>
                            {declarationsAndConsent?.consentToPay50PoundsForDBS}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I consent to take the financial responsibility for
                            the payment of £6 associated with my ID checks. This
                            payment may be made either in advance or through the
                            reimbursement of my salary, as deemed appropriate by
                            the company.
                          </TableCell>
                          <TableCell>
                            {
                              declarationsAndConsent?.consentToPay6PoundsForIDChecks
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I consent to take the financial responsibility for
                            the payment of £15 associated with my Health
                            Screening. This payment may be made either in
                            advance or through the reimbursement of my salary,
                            as deemed appropriate by the company.
                          </TableCell>
                          <TableCell>
                            {
                              declarationsAndConsent?.consentToPay15PoundsForHealthScreening
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I consent to pay for training the amount applicable
                            as per training required.
                          </TableCell>
                          <TableCell>
                            {declarationsAndConsent?.consentToPayForTheTraining}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            I have read the Agency Worker Handbook, which
                            describes my duties as an Agency Worker as well as
                            the aims, rules, rewards, and expectations of Pulse
                            Care Limited and its clients. I am familiar with the
                            information in this handbook. By signing below, I
                            certify that I have read, understand, accept, and
                            agree to abide by the instructions in the Pulse Care
                            Ltd Agency Worker Handbook. I also affirm that I am
                            aware that I must notify Pulse Care Ltd right away
                            if my eligibility to practise medicine or to
                            maintain my professional registration changes.
                          </TableCell>
                          <TableCell>
                            {
                              declarationsAndConsent?.readTheAgencyWorkerHandbook
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            By ticking this box, I acknowledge, understand,
                            accept and agree to the Pulse Care Induction Policy
                          </TableCell>
                          <TableCell>
                            {declarationsAndConsent?.acceptInductionPolicy &&
                              'Yes'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            By ticking this box, I acknowledge, understand,
                            accept and agree tothe ESG Policy
                          </TableCell>
                          <TableCell>
                            {declarationsAndConsent?.acceptESGPolicy && 'Yes'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            By ticking this box, I acknowledge, understand,
                            accept and agree to the Modern Slavery Policy
                          </TableCell>
                          <TableCell>
                            {declarationsAndConsent?.acceptModernSlaveryPolicy &&
                              'Yes'}
                          </TableCell>
                        </TableRow>
                        {/* START */}
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>
                            {personalInformation.firstName}{' '}
                            {personalInformation?.middleName}{' '}
                            {personalInformation?.lastName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Signature</TableCell>
                          <TableCell>
                            {personalInformation.firstName}{' '}
                            {personalInformation?.middleName}{' '}
                            {personalInformation?.lastName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Submission Date</TableCell>
                          <TableCell>
                            {application.finalSubmissionDateTime || ''}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  ) : (
    <Loading text="Loading..." />
  );
};
export default connect(
  (state: RootState) => ({
    applicationForm: state.applicationsReducer.applicationForm,
    loading: state.applicationsReducer.loading
  }),
  { getApplicationForm }
)(Print);
