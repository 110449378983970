export const formKeys = {
  personalInformation: 'personalInformation',
  employeePreferences: 'employeePreferences',
  availability: 'availability',
  referAFriend: 'referAFriend',
  rightToWork: 'rightToWork',
  paymentInfo: 'paymentInfo',
  employmentHistory: 'employmentHistory',
  professionalReferences: 'professionalReferences',
  workingTimeRegulation: 'workingTimeRegulation',
  fitnessToWork: 'fitnessToWork',
  healthChecklist: 'healthChecklist',
  dbs: 'dbs',
  addressHistory: 'addressHistory',
  overseasPoliceCheck: 'overseasPoliceCheck',
  declarations: 'declarations',
  declarationsAndConsent: 'declarationsAndConsent',
  documents: 'documents'
};

export const APPLICATION_SUB_FORMS = [
  'Personal Information',
  'Employee Preferences',
  'Availability',
  'Refer A Friend',
  'Right To Work',
  'Payment Info',
  'Employment History',
  'Professional References',
  'Working Time Regulation',
  'Fitness To Work',
  'Health Checklist',
  'DBS',
  'Address History',
  'Overseas Police Check',
  'Declarations',
  'Declarations And Consent',
  'Documents'
];
