import { backend } from 'src/services/http';
import {
  JOB_FETCH_SUCCESS,
  JOB_CREATE_SUCCESS,
  JOB_UPDATE_SUCCESS
} from './types';
import { toast } from 'react-toastify';

export const createJob = (job) => async (dispatch) => {
  try {
    const res = await backend.post('/jobs', job);
    dispatch({
      type: JOB_CREATE_SUCCESS,
      payload: res.data.data.job
    });
    toast('Job Application Created', { type: 'success' });
    return true;
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => toast(error.msg, { type: 'error' }));
    }
    return false;
  }
};

export const updateJob = (jobId, job) => async (dispatch) => {
  try {
    await backend.patch(`/jobs/${jobId}`, job);
    dispatch(getJobs());
    toast('Job Application Updated', { type: 'success' });
    return true;
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => toast(error.msg, { type: 'error' }));
    }
    return false;
  }
};

export const disableJob = (jobId) => async (dispatch) => {
  try {
    await backend.delete(`/jobs/${jobId}`);
    dispatch(getJobs());
    toast('Job Application Deleted', { type: 'success' });
    return true;
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => toast(error.msg, { type: 'error' }));
    }
    return false;
  }
};

export const getJobs = () => async (dispatch) => {
  try {
    const res = await backend.get('/jobs');
    dispatch({
      type: JOB_FETCH_SUCCESS,
      payload: res.data.data.jobs
    });
  } catch (error) {
    const errors = error.response.data.errors;
    errors.forEach((err) => toast(err.message, { type: 'error' }));
  }
};
