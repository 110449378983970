import React, { useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  TablePagination,
  Box,
  Button
} from '@mui/material';
import { format } from 'date-fns';
import TextBadge from 'src/components/Badge';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

const renderStage = (stage) => {
  switch (stage) {
    case 'complete':
      return <TextBadge color="success" text="Complete" />;

    case 'draft':
      return <TextBadge color="warning" text="Draft" />;

    case 'notStarted':
      return <TextBadge color="error" text="Not Completed" />;

    case 'correction':
      return <TextBadge color="default" text="Correction" />;
  }
};

const columns = [
  { id: 'email', label: 'Email' },
  { id: 'invitationDateTime', label: 'Invited At' },
  { id: 'job', label: 'Job' },
  { id: 'stage', label: 'Stage' }
];

const RecentApplicationsTable = ({ applications }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredData = applications.filter(
    (item) =>
      item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.job.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box p={2}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <TextField
          size="small"
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginBottom: 16 }}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row._id}>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {column.id === 'job'
                        ? row[column.id].title
                        : column.id === 'invitationDateTime'
                        ? format(
                            new Date(row[column.id]),
                            "dd/MM/yyyy hh:mm aaaaa'm'"
                          )
                        : column.id === 'stage'
                        ? renderStage(row[column.id])
                        : row[column.id]}
                    </TableCell>
                  ))}
                  <TableCell>
                    <Link to={`/application/${row._id}`}>
                      <Button variant="contained" color="primary" size="small">
                        View
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={applications.length}
        rowsPerPage={rowsPerPage}
        page={page}
      />
    </Box>
  );
};

export default RecentApplicationsTable;
