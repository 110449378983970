import { backend } from 'src/services/http';
import {
  APPLICATIONS_FETCH_SUCCESS,
  APPLICATION_SINGLE_FETCH_SUCCESS,
  APPLICATION_FETCH_LOADING
} from './types';
import { toast } from 'react-toastify';

export const getApplications = () => async (dispatch) => {
  try {
    dispatch({
      type: APPLICATION_FETCH_LOADING
    });
    const res = await backend.get('/applications');
    dispatch({
      type: APPLICATIONS_FETCH_SUCCESS,
      payload: res.data.data
    });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => toast(error.msg, { type: 'error' }));
    }
    return false;
  }
};

export const getApplicationForm = (id) => async (dispatch) => {
  try {
    dispatch({
      type: APPLICATION_FETCH_LOADING
    });
    const res = await backend.get('/applications/form/' + id);
    dispatch({
      type: APPLICATION_SINGLE_FETCH_SUCCESS,
      payload: res.data.data
    });
  } catch (error) {
    const errors = error.response.data.errors;
    if (errors) {
      errors.forEach((error) => toast(error.msg, { type: 'error' }));
    }
    return false;
  }
};
