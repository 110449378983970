import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container, Card } from '@mui/material';
import Footer from 'src/components/Footer';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import RecentInvitationsTable from './RecentInvitationsTable';
import { RootState } from 'src/redux/store';
import { getInvitations } from 'src/redux/actions/invitations';
import { getJobs } from 'src/redux/actions/jobs';

function Invitations({ getInvitations, invitations, getJobs }) {
  useEffect(() => {
    getJobs();
    getInvitations();
  }, []);
  return (
    <>
      <Helmet>
        <title>Invitations</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            {/* <RecentJobs /> */}
            <Card>
              <RecentInvitationsTable invitations={invitations} />
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default connect(
  (state: RootState) => ({ invitations: state.invitationsReducer.invitations }),
  { getInvitations, getJobs }
)(Invitations);
