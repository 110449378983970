import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem
} from '@mui/material';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import Footer from 'src/components/Footer';
import { FieldArray, Form, Formik } from 'formik';
import { createJob, updateJob } from 'src/redux/actions/jobs';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

type Props = {
  open;
  setOpen;
  updateJob?;
  jobId;
  loading;
};

function EditJobModal({ open, setOpen, updateJob, jobId, loading }: Props) {
  const job = useSelector((state: RootState) =>
    state.jobsReducer.jobs.find((job) => job._id === jobId)
  );
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
      <Formik
        initialValues={{
          title: job?.title,
          overview: job?.overview,
          description: job?.description,
          location: job?.location
        }}
        onSubmit={async (values) => {
          const created = await updateJob(job._id, values);
          if (created) {
            setOpen(false);
          }
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form noValidate autoComplete="off">
            <DialogTitle>Create a Job</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Fields marked with (*) are mandatory and cannot be left blank
              </DialogContentText>
              <Grid container mt={2} spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    label="Title"
                    onChange={handleChange}
                    fullWidth
                    name="title"
                    value={values.title}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    multiline
                    rows={3}
                    label="Overview"
                    onChange={handleChange}
                    fullWidth
                    name="overview"
                    value={values.overview}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    multiline
                    rows={6}
                    label="Description"
                    onChange={handleChange}
                    fullWidth
                    name="description"
                    value={values.description}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label="Location"
                    onChange={handleChange}
                    fullWidth
                    name="location"
                    value={values.location}
                  />
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button type="submit">Create</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

export default connect(
  (state: RootState) => ({ loading: state.jobsReducer.loading }),
  { updateJob }
)(EditJobModal);
