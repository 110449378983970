export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const LOGOUT = 'LOGOUT';

export const JOB_CREATE_SUCCESS = 'JOB_CREATE_SUCCESS';
export const JOB_FETCH_SUCCESS = 'JOB_FETCH_SUCCESS';
export const JOB_CREATE_FAIL = 'JOB_CREATE_FAIL';
export const JOB_UPDATE_SUCCESS = 'JOB_UPDATE_SUCCESS';

export const INVITATIONS_FETCH_SUCCESS = 'INVITATIONS_FETCH_SUCCESS';
export const INVITATIONS_CREATE_SUCCESS = 'INVITATIONS_CREATE_SUCCESS';
export const INVITATIONS_CREATE_FAIL = 'INVITATIONS_CREATE_FAIL';
export const INVITATIONS_SINGLE_FETCH_SUCCESS =
  'INVITATIONS_SINGLE_FETCH_SUCCESS';

export const APPLICATIONS_FETCH_SUCCESS = 'APPLICATIONS_FETCH_SUCCESS';
export const APPLICATION_SINGLE_FETCH_SUCCESS =
  'APPLICATION_SINGLE_FETCH_SUCCESS';
export const APPLICATION_FETCH_LOADING = 'APPLICATION_FETCH_LOADING';
