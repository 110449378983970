import { Box, CircularProgress } from '@mui/material';
import { height } from '@mui/system';
import React from 'react';

type Props = {
  text: string;
};

const Loading = (props: Props) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={2}
      height={'100vh'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <CircularProgress title="Loading" size={32} />
      {props.text}
    </Box>
  );
};

export default Loading;
