import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container, Card } from '@mui/material';
import Footer from 'src/components/Footer';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import RecentInvitationsTable from './RecentApplicationsTable';
import { RootState } from 'src/redux/store';
import { getInvitations } from 'src/redux/actions/invitations';
import { getJobs } from 'src/redux/actions/jobs';
import RecentApplicationsTable from './RecentApplicationsTable';
import { getApplications } from 'src/redux/actions/applications';

function Applications({ getApplications, applications }) {
  useEffect(() => {
    getApplications();
  }, []);
  return applications ? (
    <>
      <>
        <Helmet>
          <title>Applications</title>
        </Helmet>
        <PageTitleWrapper>
          <PageHeader />
        </PageTitleWrapper>
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
            // minHeight={'60vh'}
          >
            <Grid item xs={12}>
              {/* <RecentJobs /> */}
              <Card>
                <RecentApplicationsTable applications={applications} />
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </>
    </>
  ) : (
    <></>
  );
}

export default connect(
  (state: RootState) => ({
    applications: state.applicationsReducer.applications
  }),
  { getApplications }
)(Applications);
