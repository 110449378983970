import { combineReducers } from 'redux';
import authReducer from './auth';
import jobsReducer from './jobs';
import invitationsReducer from './invitation';
import applicationsReducer from './application';

const rootReducer = combineReducers({
  authReducer,
  jobsReducer,
  invitationsReducer,
  applicationsReducer
});

export default rootReducer;
