import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import { RootState } from 'src/redux/store';

const PrivateRoute = ({ children, loggedIn, loading }) => {
  if (!loggedIn && !loading) {
    return <Navigate to="/login" replace />;
  }

  return <SidebarLayout>{children}</SidebarLayout>;
};

export default connect((state: RootState) => ({
  loggedIn: state.authReducer.loggedIn,
  loading: state.authReducer.loading
}))(PrivateRoute);
