import {
  INVITATIONS_FETCH_SUCCESS,
  INVITATIONS_CREATE_SUCCESS,
  INVITATIONS_SINGLE_FETCH_SUCCESS
} from '../actions/types';

const initialState = {
  invitations: [],
  created: false,
  loading: true,
  currentInvitation: {}
};

const invitationsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case INVITATIONS_CREATE_SUCCESS:
      return {
        ...state,
        invitations: [payload, ...state.invitations]
      };

    case INVITATIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        invitations: payload
      };

    case INVITATIONS_SINGLE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        currentInvitation: payload
      };

    default:
      return state;
  }
};

export default invitationsReducer;
