import { Typography, Avatar, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';

function PageHeader({ firstName }) {
  const user = {
    name: firstName,
    avatar: '/static/images/avatars/rj.jpg'
  };
  const theme = useTheme();

  return (
    <Grid container alignItems="center">
      <Grid item>
        <Avatar
          sx={{
            mr: 2,
            mb: 2,
            width: theme.spacing(8),
            height: theme.spacing(8)
          }}
          variant="rounded"
          alt={user.name}
          src={user.avatar}
        />
      </Grid>
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Welcome, {user.name}!
        </Typography>
        <Typography variant="subtitle2">
          It's a wonderful day near you. Let's manage your job applications.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default connect((state: RootState) => ({
  firstName: state.authReducer.user.firstName
}))(PageHeader);
