import { format } from 'date-fns';
import { toast } from 'react-toastify';

export function formatString(inputString) {
  let words = inputString.split(/[_ ]+/);

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].slice(1).toLowerCase();
  }

  return words.join(' ');
}

export const renderHttpErrors = (error, autoClose?) => {
  const errors = error.response.data.errors;
  errors.forEach((error) => toast(error.msg, { type: 'error', autoClose }));
};

export const getDateFormatted = (date) => {
  if (date) {
    return format(new Date(date), 'dd/MM/yyyy');
  }

  return '';
};
