import { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  Box,
  DialogActions,
  Tooltip,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import {
  AddBox,
  AddBoxOutlined,
  EmailOutlined,
  Link,
  PersonPinCircleOutlined,
  PlusOne,
  PrintOutlined,
  RemoveCircleOutline,
  RemoveOutlined
} from '@mui/icons-material';
import Loading from 'src/components/Loading';
import { FieldArray, Form, Formik } from 'formik';
import { APPLICATION_SUB_FORMS } from 'src/constants';
import { backend } from 'src/services/http';
import { renderHttpErrors } from 'src/utils';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { useNavigate } from 'react-router';

function PageHeader({ title, handlePrint }) {
  const navigate = useNavigate();

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={6}>
          <Typography variant="h3" component="h3" gutterBottom>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign={'end'}>
          <Button variant='outlined' color='primary' size='medium' onClick={handlePrint}>Print</Button>
        </Grid>
      </Grid>
    </>
  );
}

export default PageHeader;
