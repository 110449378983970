import { backend } from 'src/services/http';
import {
  JOB_FETCH_SUCCESS,
  JOB_CREATE_SUCCESS,
  JOB_UPDATE_SUCCESS,
  INVITATIONS_FETCH_SUCCESS,
  INVITATIONS_CREATE_SUCCESS,
  INVITATIONS_SINGLE_FETCH_SUCCESS
} from './types';
import { toast } from 'react-toastify';
import { renderHttpErrors } from 'src/utils';

export const createInvitation =
  ({ job, invitees, title }) =>
  async (dispatch) => {
    console.log(job);
    try {
      const res = await backend.post('/invitations', {
        jobId: job.id,
        invitees,
        title
      });
      dispatch({
        type: INVITATIONS_CREATE_SUCCESS,
        payload: res.data.data
      });
      toast('Invitation Sent', { type: 'success' });
      return true;
    } catch (error) {
      const errors = error.response.data.errors;
      if (errors) {
        errors.forEach((error) => toast(error.msg, { type: 'error' }));
      }
      return false;
    }
  };

export const getInvitations = () => async (dispatch) => {
  try {
    const res = await backend.get('/invitations');
    dispatch({
      type: INVITATIONS_FETCH_SUCCESS,
      payload: res.data.data
    });
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

export const getSingleInvitation = (invitationId) => async (dispatch) => {
  try {
    const res = await backend.get(`/invitations/${invitationId}`);
    dispatch({
      type: INVITATIONS_SINGLE_FETCH_SUCCESS,
      payload: res.data.data
    });
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

export const resendInvite = (invitationId, invitees) => async (dispatch) => {
  try {
    await backend.post(`/invitations/resend-invite`, {
      invitationId,
      invitees
    });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};
