import { useState } from 'react';
import { Typography, Button, Grid } from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import NewInviteModal from './NewInviteModal';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

function PageHeader() {
  const [openNewInviteModal, setOpenNewInviteModal] = useState(false);

  const username = useSelector(
    (state: RootState) => state.authReducer.user.firstName
  );

  const user = {
    name: username,
    avatar: '/static/images/avatars/1.jpg'
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Under Development
        </Typography>
        <Typography variant="subtitle2">
          Future place of something really cool
        </Typography>
      </Grid>
      <Grid item>
        {/* <Button
          sx={{ mt: { xs: 2, md: 0 } }}
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
          onClick={() => setOpenNewInviteModal(true)}
        >
          New Invite
        </Button> */}
      </Grid>
      {/* <NewInviteModal
        open={openNewInviteModal}
        setOpen={setOpenNewInviteModal}
      /> */}
    </Grid>
  );
}

export default PageHeader;
