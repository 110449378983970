import { useState } from 'react';
import { format } from 'date-fns';
import {
  Divider,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Button
} from '@mui/material';
import InvitationSummaryModal from './InvitationSummaryModal';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import { getSingleInvitation } from 'src/redux/actions/invitations';
import { RootState } from 'src/redux/store';
import Loading from 'src/components/Loading';

const columns: GridColDef[] = [
  { field: 'id', headerName: '#', flex: 1, minWidth: 50 },
  { field: 'title', headerName: 'Title', flex: 3, minWidth: 100 },
  { field: 'job', headerName: 'Job', flex: 2, minWidth: 100 },
  { field: 'createdAt', headerName: 'Sent At', flex: 2, minWidth: 100 },
  { field: 'invitees', headerName: 'Invitees', flex: 1, minWidth: 100 }
  // { field: 'applications', headerName: 'Applicantions', flex: 1, minWidth: 100 }
];

const RecentInvitationsTable = ({
  invitations,
  invitationsLoading,
  getSingleInvitation,
  invitation
}) => {
  const [invitationSummaryModalOpen, setInvitationSummaryModalOpen] =
    useState(false);
  const [selectedInvitation, setSelectedInvitation] = useState(null);

  const rows = invitations.map((invitation, index) => {
    return {
      id: index + 1,
      invitationId: invitation._id,
      title: invitation.title,
      job: invitation.job.title,
      createdAt: format(new Date(invitation.createdAt), 'd MMMM yyyy (HH:mm)'),
      invitees: invitation.invitees.length
      // applications: invitation.invitees.length / 2
    };
  });

  const handleRowClick = (e) => {
    getSingleInvitation(e.row.invitationId);
    setInvitationSummaryModalOpen(true);
  };

  return !invitationsLoading ? (
    <Card>
      <Divider />
      <DataGrid
        slots={
          {
            // toolbar: GridToolbar
          }
        }
        onRowClick={handleRowClick}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 6 }
          }
        }}
        pageSizeOptions={[5, 10]}
      />
      <InvitationSummaryModal
        open={invitationSummaryModalOpen}
        setOpen={setInvitationSummaryModalOpen}
        invitation={invitation}
      />
    </Card>
  ) : (
    <Loading text="Loading..." />
  );
};

export default connect(
  (state: RootState) => ({
    invitationsLoading: state.invitationsReducer.loading,
    invitation: state.invitationsReducer.currentInvitation
  }),
  { getSingleInvitation }
)(RecentInvitationsTable);
