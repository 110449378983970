import { EmailOutlined, FileCopyOutlined } from '@mui/icons-material';
import {
  Badge,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { resendInvite } from 'src/redux/actions/invitations';
import { RootState } from 'src/redux/store';

type Props = {
  open;
  setOpen;
  invitation;
  resendInvite;
};

const stages = {
  INVITED: 'invited',
  SEEN: 'seen'
};

const getStage = (stage) => {
  switch (stage) {
    case stages.INVITED:
      return <Badge badgeContent={'Invited'} color="secondary" />;

    case stages.SEEN:
      return <Badge badgeContent={'Seen'} color="success" />;

    default:
      <Badge badgeContent={'Unknown'} />;
  }
};

function InvitationSummaryModal({
  open,
  setOpen,
  invitation,
  resendInvite
}: Props) {
  const rows = invitation?.invitees?.map((invitee) => ({
    id: invitee._id,
    email: invitee.email,
    stage: invitee.stage,
    job: invitation.job.title,
    applicationLink: invitee.applicationLink
  }));

  const handleResendInvite = async () => {
    const sent = resendInvite(
      invitation._id,
      invitation?.invitees?.reduce((acc, curr) => {
        if (!acc.includes(curr.email)) {
          acc.push(curr.email);
          return acc;
        }
      }, [])
    );
    if (sent) {
      toast('Invitation sent.', { type: 'success' });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      {/*  */}
      <DialogTitle>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Box>
            <Typography variant="body2">{invitation?.title}</Typography>
          </Box>

          {/* <Box display={'flex'} gap={2}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(invitation.applicationLink);
                toast('Application link copied to clipboard', {
                  type: 'success'
                });
              }}
              startIcon={<EmailOutlined />}
              size="small"
              variant="contained"
              color="primary"
            >
              Resend Invite
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(invitation.applicationLink);
                toast('Application link copied to clipboard', {
                  type: 'success'
                });
              }}
              startIcon={<EmailOutlined />}
              size="small"
              variant="contained"
              color="primary"
            >
              Add Invitee
            </Button>
          </Box> */}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ maxHeight: '280px' }}>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell>Link</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((invitee) => (
                    <TableRow key={invitee.id}>
                      <TableCell>{invitee.email}</TableCell>
                      <TableCell align="center">
                        {getStage(invitee.stage)}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            navigator.clipboard.writeText(
                              invitee.applicationLink
                            );
                            toast('Application link copied to clipboard', {
                              type: 'success'
                            });
                          }}
                          startIcon={<FileCopyOutlined />}
                          size="small"
                          variant="outlined"
                          color="primary"
                        >
                          Application Link
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={handleResendInvite}
                          startIcon={<FileCopyOutlined />}
                          size="small"
                          variant="outlined"
                          color="primary"
                        >
                          Resend Invite
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default connect(
  (state: RootState) => ({ jobs: state.jobsReducer.jobs }),
  { resendInvite }
)(InvitationSummaryModal);
