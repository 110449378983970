import { CheckOutlined, PrintOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { PrintBreak } from 'src/components/PrintBreak';
import { getDateFormatted } from 'src/utils';

type Props = {
  data;
  printRef?;
};

const styles = makeStyles({
  container: {
    padding: '48px',
    // border: '2px solid #ccc',
    display: 'flex',
    gap: '32px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
    // height: '29.7cm',
    // width: '21cm'
  },
  table: {
    '& tr td': {
      border: '2px solid #000',
      height: '32px'
    },
    '& tr th': {
      border: '2px solid #000',
      height: '32px',
      background: '#000',
      color: '#fff',
      textAlign: 'center'
    }
  }
});

const SEQOHS = (props: Props) => {
  const data = props.data;
  const classes = styles();
  const seqohsRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => seqohsRef.current
    // bodyClass: 'print-body'
  });

  return props.data ? (
    <>
      <Box display={'flex'} justifyContent={'flex-end'} mb={4}>
        <Button
          startIcon={<PrintOutlined />}
          variant="contained"
          color="success"
          onClick={handlePrint}
        >
          Print / Export
        </Button>
      </Box>
      <Box className={`${classes.container} seqohs-form`} ref={seqohsRef}>
        {/* Header */}
        <Box display={'flex'} gap={2} justifyContent={'space-between'}>
          <Box>
            <img height={120} src="/static/images/hbg-logo.jpg" />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Typography fontWeight={'bold'} variant="h3" align="center">
              OCCUPATIONAL HEALTH MEDICAL QUESTIONNAIRE
            </Typography>
            <Typography fontWeight={'bold'} color={'error'} align="center">
              (NEW STARTER CLINICAL FORM)
            </Typography>
          </Box>
          <Box>
            <img height={120} src="/static/images/seqohs.jpg" />
          </Box>
        </Box>

        {/* Confidentiality */}
        <Box display={'flex'} flexDirection="column" gap={2}>
          <Typography variant="h4" fontWeight={'bold'}>
            CONFIDENTIAL
          </Typography>
          <Typography>
            Due to the nature of the role you have applied for we need to carry
            out an assessment of a new starter health questionnaire – even if
            you have been employed in UK health services before. The health of
            each candidate is considered individually and a decision regarding
            fitness for work in the prospective job role will be based on the
            functional effects of any underlying health
            condition/disability/impairment as well as health service
            requirements for fitness and immune status.
          </Typography>
          <Typography>
            Before health clearance is given for employment you may be contacted
            by telephone from a clinician at Healthier Business UK Ltd, however
            you may also need to be seen by an occupational health
            advisor/specialist or physician, arrangements for face to face
            consultations will be arranged by your employer or agency. We may
            recommend adjustments or assistance following an assessment to
            enable you to carry out your proposed duties safely and effectively.
            Recommendations to your employer will be directed to essential
            information regarding your health and the hazards and risks of your
            employment and with due reference to other relevant statutory
            requirements and professional practice. Our aim is to promote and
            maintain the health of all individuals in the workplace: staff,
            service users and third parties. Your records will be retained
            electronically in accordance with best practice and the requirements
            of the General Data Protection Regulations. Your records will be
            held on file for the purposes of processing your request only and
            for no longer than is necessary, however your records may be subject
            to internal clinical audits. Your records may also be used to cross
            reference and ascertain your fitness should you register with other
            clients of Healthier Business UK Ltd.
          </Typography>
        </Box>

        {/* Table - Personal Information */}
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={6}>Personal Information</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell colSpan={2}>Surname</TableCell>
                <TableCell colSpan={2}>First Names</TableCell>
                <TableCell>DOB</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{data?.personalInformation?.title}</TableCell>
                <TableCell colSpan={2}>
                  {data?.personalInformation?.surname}
                </TableCell>
                <TableCell colSpan={2}>
                  {data?.personalInformation?.firstname}
                </TableCell>
                <TableCell>
                  {data?.personalInformation?.dob
                    ? format(
                        new Date(data?.personalInformation?.dob),
                        'dd/MM/yyyy'
                      )
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Home Tel:</TableCell>
                <TableCell>{data?.personalInformation?.homeTel}</TableCell>
                <TableCell>Work Tel:</TableCell>
                <TableCell>{data?.personalInformation?.workTel}</TableCell>
                <TableCell>Mobile:</TableCell>
                <TableCell>{data?.personalInformation?.mobile}</TableCell>
              </TableRow>
              <TableRow sx={{ height: '100px', verticalAlign: 'top' }}>
                <TableCell>Home Address</TableCell>
                <TableCell colSpan={2}>
                  {data?.personalInformation?.homeAddress}
                </TableCell>
                <TableCell>GP Address</TableCell>
                <TableCell colSpan={2}>
                  {data?.personalInformation?.gpAddress}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Medical History */}
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead sx={{ background: 'red' }}>
              <TableRow>
                <TableCell colSpan={3}>Medical History</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>All staff groups complete this section</TableCell>
                <TableCell>Yes</TableCell>
                <TableCell>No</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Do you have any illness/impairment/disability (physical or
                  psychological) which may affect your work?
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.doYouHaveAnyIllness === 'Yes' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.doYouHaveAnyIllness === 'No' && (
                    <CheckOutlined />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Have you ever had any illness/impairment/disability which may
                  have been caused or made worse by your work?
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.haveYouHadAnyIllness === 'Yes' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.haveYouHadAnyIllness === 'No' && (
                    <CheckOutlined />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Are you having, or waiting for treatment (including
                  medication) or investigations at present?
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.awaitingTreatment === 'Yes' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.awaitingTreatment === 'No' && (
                    <CheckOutlined />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Do you think you may need any adjustments or assistance to
                  help you to do the job?
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.assistanceNeededToPerformJob ===
                    'Yes' && <CheckOutlined />}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.assistanceNeededToPerformJob ===
                    'No' && <CheckOutlined />}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Medical History Continued */}
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={4}>Medical History (Continued)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  Have you suffered from any of the following?
                </TableCell>
                <TableCell>Yes</TableCell>
                <TableCell>No</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Methicillin resistant staphylococcus aureus (MRSA)
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.sufferedFromMrsa === 'Yes' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.sufferedFromMrsa === 'No' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.sufferedFromMrsa &&
                    getDateFormatted(data?.medicalHistory?.mrsaDate)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Clostridium difficile (C-Diff)</TableCell>
                <TableCell>
                  {data?.medicalHistory?.sufferedFromCDiff === 'Yes' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.sufferedFromCDiff === 'No' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.sufferedFromCDiff &&
                    getDateFormatted(data?.medicalHistory?.cdiffDate)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Red Description */}
        <Box>
          <Typography fontWeight={'bold'} color={'error'}>
            If you have indicated yes to any of the above questions you must
            provide further details in additional information section, failure
            to do so will result in the form being returned/rejected.
          </Typography>
        </Box>

        {/* Additional Information Table */}
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  Additional Information
                  <br /> (If you have answered yes to any questions above please
                  provide additional information below, including dates,
                  treatment and details of condition)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ height: '100px', verticalAlign: 'top' }}>
                <TableCell>
                  {data?.medicalHistory?.mrsaAndCDiffDetails}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* PAGE BREAK - 1 */}
        <PrintBreak />

        {/* Chicken Pox or Shingles */}
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}>Chicken Pox or Shingles</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={3} align="center">
                  Have you ever had chicken pox or shingles?
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Yes</TableCell>
                <TableCell>No</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {data?.medicalHistory?.everHadChickenPoxOrShingles ===
                    'Yes' && <CheckOutlined />}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.everHadChickenPoxOrShingles ===
                    'No' && <CheckOutlined />}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.chickenPoxDate
                    ? format(
                        new Date(data?.medicalHistory?.chickenPoxDate),
                        'dd/MM/yyyy'
                      )
                    : ''}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* BBV */}
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={5}>BBV (Blood Borne Virus)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  Have you ever come into contact with any BBV's? Including
                  Needle Stick Injuries?
                </TableCell>
                <TableCell>Yes</TableCell>
                <TableCell>
                  {data?.medicalHistory?.everHadContactWithBbvs === 'Yes' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>No</TableCell>
                <TableCell>
                  {data?.medicalHistory?.everHadContactWithBbvs === 'No' && (
                    <CheckOutlined />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Tuburclolsis */}
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}>Tuberculosis</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  Clinical diagnosis and management of tuberculosis, and
                  measures for its prevention and control (NICE 2016)
                </TableCell>
                <TableCell>Yes</TableCell>
                <TableCell>No</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Have you lived outside the UK or had an extended holiday
                  outside the UK in the last year?
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.livedOutsideUk === 'Yes' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.livedOutsideUk === 'No' && (
                    <CheckOutlined />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} sx={{ color: 'red' }}>
                  If you answered YES to the above, please list all the
                  countries that you have lived in/visited over the last year,
                  including holidays and vacations. This MUST include duration
                  of stay and dates or this form will be rejected.
                </TableCell>
              </TableRow>
              <TableRow sx={{ height: '100px' }}>
                <TableCell colSpan={3}>
                  {data?.medicalHistory?.detailsAboutPastTravel &&
                    data?.medicalHistory?.detailsAboutPastTravel.join(', ')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Have you had a BCG vaccination in relation to Tuberculosis?
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.everHadBcgVaccine === 'Yes' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.everHadBcgVaccine === 'No' && (
                    <CheckOutlined />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>If you answered yes, please state when</TableCell>
                <TableCell colSpan={2}>
                  {data?.medicalHistory?.bcgVaccineDate
                    ? format(
                        new Date(data?.medicalHistory?.bcgVaccineDate),
                        'dd/MM/yyyy'
                      )
                    : ''}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Tuburculosis Cont. */}
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}>Tuberculosis Continued</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  Do you have any of the following
                </TableCell>
                <TableCell>Yes</TableCell>
                <TableCell>No</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  A cough which has lasted for more than 3 weeks
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.coughForMoreThanThreeWeeks ===
                    'Yes' && <CheckOutlined />}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.coughForMoreThanThreeWeeks ===
                    'No' && <CheckOutlined />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Unexplained weight loss</TableCell>
                <TableCell>
                  {data?.medicalHistory?.unexplanedWeightLoss === 'Yes' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.unexplanedWeightLoss === 'No' && (
                    <CheckOutlined />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Unexplained fever</TableCell>
                <TableCell>
                  {data?.medicalHistory?.unexplainedFever === 'Yes' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.unexplainedFever === 'No' && (
                    <CheckOutlined />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  Have you had tuberculosis (TB) or been in recent contact with
                  open TB
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.everHadTb === 'Yes' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.everHadTb === 'No' && (
                    <CheckOutlined />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Additional Information */}
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  Additional Information <br /> (If you have answered yes to any
                  questions above please provide additional information below)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow sx={{ height: '100px' }}>
                <TableCell>{data?.medicalHistory?.tbDetails}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Immunization History */}
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={4}>Immunization History</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell width={400}>
                  Have you had any of the following immunisations
                </TableCell>
                <TableCell>Yes</TableCell>
                <TableCell>No</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Triple vaccination as a child (Diptheria / Tetanus / Whooping
                  cough)
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.hadTrippleVaccination === 'Yes' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.hadTrippleVaccination === 'No' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.trippleVaccinationDate &&
                    format(
                      new Date(data?.medicalHistory?.trippleVaccinationDate),
                      'dd/MM/yyyy'
                    )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Polio</TableCell>
                <TableCell>
                  {data?.medicalHistory?.hadPolioVaccination === 'Yes' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.hadPolioVaccination === 'No' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.polioVaccinationDate &&
                    format(
                      new Date(data?.medicalHistory?.polioVaccinationDate),
                      'dd/MM/yyyy'
                    )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tetanus</TableCell>
                <TableCell>
                  {data?.medicalHistory?.hadTetanus === 'Yes' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.hadTetanus === 'No' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.tetanusDate &&
                    format(
                      new Date(data?.medicalHistory?.tetanusDate),
                      'dd/MM/yyyy'
                    )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Hepatitis B (If Yes is ticked please give dates below)
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.hepatitisB === 'Yes' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.medicalHistory?.hepatitisB === 'No' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Course:</TableCell>
                <TableCell>
                  1:{' '}
                  {data?.medicalHistory?.hepatitisVaccineDates.course[0] &&
                    format(
                      new Date(
                        data?.medicalHistory?.hepatitisVaccineDates.course[0]
                      ),
                      'dd/MM/yyyy'
                    )}
                </TableCell>
                <TableCell>
                  2:{' '}
                  {data?.medicalHistory?.hepatitisVaccineDates.course[1] &&
                    format(
                      new Date(
                        data?.medicalHistory?.hepatitisVaccineDates.course[1]
                      ),
                      'dd/MM/yyyy'
                    )}
                </TableCell>
                <TableCell>
                  3:{' '}
                  {data?.medicalHistory?.hepatitisVaccineDates.course[2] &&
                    format(
                      new Date(
                        data?.medicalHistory?.hepatitisVaccineDates.course[2]
                      ),
                      'dd/MM/yyyy'
                    )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Boosters:</TableCell>
                <TableCell>
                  1:{' '}
                  {data?.medicalHistory?.hepatitisVaccineDates.boosters[0] &&
                    format(
                      new Date(
                        data?.medicalHistory?.hepatitisVaccineDates.boosters[0]
                      ),
                      'dd/MM/yyyy'
                    )}
                </TableCell>
                <TableCell>
                  2:{' '}
                  {data?.medicalHistory?.hepatitisVaccineDates.boosters[1] &&
                    format(
                      new Date(
                        data?.medicalHistory?.hepatitisVaccineDates.boosters[1]
                      ),
                      'dd/MM/yyyy'
                    )}
                </TableCell>
                <TableCell>
                  3:{' '}
                  {data?.medicalHistory?.hepatitisVaccineDates.boosters[2] &&
                    format(
                      new Date(
                        data?.medicalHistory?.hepatitisVaccineDates.boosters[2]
                      ),
                      'dd/MM/yyyy'
                    )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* PAGE BREAK 2 */}
        <PrintBreak />

        {/* Proof of Immunity */}
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  Proof of Immunity (Please send the following)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Varicella</TableCell>
                <TableCell>
                  You must provide a written statement to confirm that you have
                  had chicken pox or shingles however we strongly advise that
                  you provide serology test result showing varicella immunity
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tuburculosis</TableCell>
                <TableCell>
                  We require an occupational health/GP certificate of a positive
                  scar or a record of a positive skin test result (Do not Self
                  Declare)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Rubella, Measles & Mumps</TableCell>
                <TableCell>
                  Certificate of “two” MMR vaccinations or proof of a positive
                  antibody for Rubella and Measles
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Hepatitis B</TableCell>
                <TableCell>
                  You must provide a copy of the most recent pathology report
                  showing titre levels of 100lu/l or above
                </TableCell>
              </TableRow>
              <TableRow sx={{ backgroundColor: '#000' }}>
                <TableCell
                  sx={{ color: '#fff', fontWeight: 'bold' }}
                  colSpan={2}
                  align="center"
                >
                  Proof of Immunity (Please send the following) EPP Candidates
                  Only
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Hepatitis B Surface Antigen</TableCell>
                <TableCell>
                  Evidence of Hepatitis B Surface Antigen Test (Inc. 'e' antigen
                  and DNA viral loads if applicable). Report must be an
                  identified validated sample. (IVS)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Hepatitis C</TableCell>
                <TableCell>
                  Evidence of a Hepatitis C antibody test (Inc. Hepatitis C
                  RNA/PCR if applicable) Reports must be an identified validated
                  sample. (IVS)
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>HIV</TableCell>
                <TableCell>
                  Evidence of a HIV I and II antibody test (Inc. DNA viral loads
                  if applicable) Reports must be an identified validated sample.
                  (IVS)
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Exposure Prone Procedures */}
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={5}>Exposure Prone Procedures</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  Will your role involve Exposure Prone Procedures
                </TableCell>
                <TableCell>Yes</TableCell>
                <TableCell>
                  {data?.medicalHistory
                    ?.willYourRoleInvolveExposureProneProcedures === 'Yes' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>No</TableCell>
                <TableCell>
                  {data?.medicalHistory
                    ?.willYourRoleInvolveExposureProneProcedures === 'No' && (
                    <CheckOutlined />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* UK law */}
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  UK General Data Protection Regulation (UK GDPR)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">
                  All information supplied by you will be held in confidence by
                  Healthier Business UK Ltd. Records will be retained
                  electronically in accordance with best practice and the
                  requirements of the General Data Protection Regulations at
                  which time it may be subject to audit. Your data may also be
                  cross referenced should you have registered with other clients
                  of Healthier Business UK Ltd. Your personal data may be
                  required to be seen by an occupational health advisor or
                  physician; however it will not be shown, nor their contents
                  shared with anyone - including Managers, Human Resources
                  Advisors, GP's, Specialists or third party's - without your
                  explicit consent. You have the right of erasure (the right to
                  be forgotten), refusal of consent and withdrawal of consent
                  without detriment (withdrawal of consent can be exercised at
                  any stage of the process). The only exceptions to this may be
                  a court order for release of records in a judicial dispute or
                  where there is a public responsibility obligation. Further
                  information regarding your rights under GDPR can be found on
                  the following:
                  <a href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulationgdpr/individual-rights/">
                    https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulationgdpr/individual-rights/
                  </a>
                  If you wish to have sight of our privacy policy, please send
                  your request to{' '}
                  <a href="mailto:support@hbcompliance.co.uk">
                    support@hbcompliance.co.uk
                  </a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Consent */}
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}>Consent</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={3} align="center">
                  Consent is a process rather than a one off decision, for
                  consent to be valid, it must be voluntary and informed. You
                  have the right to withdraw your consent at any stage of the
                  process, either verbally or in writing. Further information
                  regarding consent is available on the ‘Candidate Screening
                  Leaflet’.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                  All staff groups complete this section
                </TableCell>
                <TableCell>Yes</TableCell>
                <TableCell>No</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Do you consent to this questionnaire and your immunisation
                  reports being assessed by an Occupational Health Advisor for
                  the purpose of providing a Fitness to Work Certificate?
                </TableCell>
                <TableCell>
                  {data?.consent?.consentForReportsBeingAssessed === 'Yes' && (
                    <CheckOutlined />
                  )}
                </TableCell>
                <TableCell>
                  {data?.consent?.consentForReportsBeingAssessed === 'No' && (
                    <CheckOutlined />
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Do you consent to our Occupational Health Advisors speaking
                  with you regarding any declaration you may have made relating
                  to your medical history?
                </TableCell>
                <TableCell>
                  {data?.consent
                    ?.consentToOurOccupationalHealthAdvisorsSpeakingWithYou ===
                    'Yes' && <CheckOutlined />}
                </TableCell>
                <TableCell>
                  {data?.consent
                    ?.consentToOurOccupationalHealthAdvisorsSpeakingWithYou ===
                    'No' && <CheckOutlined />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Do you consent to our Occupational Health Advisors making
                  recommendations to your employer/agency to assist with your
                  ability to carry out your prospective role?
                </TableCell>
                <TableCell>
                  {data?.consent
                    ?.consentToOurOccupationalHealthAdvisorsMakingRecommendations ===
                    'Yes' && <CheckOutlined />}
                </TableCell>
                <TableCell>
                  {data?.consent
                    ?.consentToOurOccupationalHealthAdvisorsMakingRecommendations ===
                    'No' && <CheckOutlined />}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Declaration */}
        <TableContainer>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}>Declaration</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={3} align="center">
                  I will inform my employer if I am planning to or leave the UK
                  for longer than a three-month period to enable a reassessment
                  of my health to be conducted on my return. I declare that the
                  answers to the above questions are true and complete to the
                  best of my knowledge and belief.
                </TableCell>
              </TableRow>
              <TableRow sx={{ background: '#000' }}>
                <TableCell
                  align="center"
                  sx={{ fontWeight: 'bold', color: '#fff' }}
                >
                  Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: 'bold', color: '#fff' }}
                >
                  Signature
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: 'bold', color: '#fff' }}
                >
                  Date
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {data?.personalInformation?.firstname}{' '}
                  {data?.personalInformation?.surname}
                </TableCell>
                <TableCell>{data?.personalInformation?.firstname} </TableCell>
                <TableCell>{getDateFormatted(data?.createdAt)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  ) : (
    <Typography>Loading SEQOHS data</Typography>
  );
};

export default SEQOHS;
