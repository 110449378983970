import {
  APPLICATIONS_FETCH_SUCCESS,
  APPLICATION_FETCH_LOADING,
  APPLICATION_SINGLE_FETCH_SUCCESS
} from '../actions/types';

const initialState = {
  applications: [],
  loading: true,
  applicationForm: {}
};

const applicationsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case APPLICATIONS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        applications: payload
      };

    case APPLICATION_SINGLE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        applicationForm: payload
      };

    case APPLICATION_FETCH_LOADING:
      return {
        ...state,
        loading: true
      };

    default:
      return state;
  }
};

export default applicationsReducer;
