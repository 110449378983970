import { Navigate, Route, Routes, useRoutes } from 'react-router-dom';
import router from 'src/routing/router';

import { Box, CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PrivateRoute from './routing/PrivateRoute';
import Dashboard from './pages/dashboard';
import Login from './pages/login';
import { useEffect } from 'react';
import { backend } from './services/http';
import store from './redux/store';
import { loadUser } from './redux/actions/auth';
// import Applications from './pages/applications';
import Jobs from './pages/jobs';
// import Application from './pages/application';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Invitations from './pages/invitations';
import UnderDevelopment from './pages/UnderDevelopment';
import Applications from './pages/applications';
import Application from './pages/application';

const data = {
  personalInformation: {
    title: 'Mr',
    surname: 'Yaqoob',
    firstname: 'Mohsin',
    dob: '2023-10-25T18:30:00.000Z',
    homeTel: '09055404321',
    workTel: '',
    mobile: '09055404321',
    homeAddress: 'Zadi Masjid Chai Dub Dana Mazar Road Safakadal',
    gpAddress: '118'
  },
  medicalHistory: {
    hepatitisVaccineDates: {
      course: [
        '2023-11-05T18:30:00.000Z',
        '2023-11-06T18:30:00.000Z',
        '2023-11-07T18:30:00.000Z'
      ],
      boosters: [
        '2023-11-08T18:30:00.000Z',
        '2023-11-09T18:30:00.000Z',
        '2023-11-10T18:30:00.000Z'
      ]
    },
    doYouHaveAnyIllness: 'No',
    haveYouHadAnyIllness: 'No',
    awaitingTreatment: 'No',
    assistanceNeededToPerformJob: 'No',
    sufferedFromMrsa: 'No',
    sufferedFromCDiff: 'No',
    everHadChickenPoxOrShingles: 'No',
    everHadContactWithBbvs: 'No',
    livedOutsideUk: 'Yes',
    detailsAboutPastTravel: ['Afghanistan', 'Algeria', 'AndorrA', 'Anguilla'],
    everHadBcgVaccine: 'No',
    coughForMoreThanThreeWeeks: 'No',
    unexplanedWeightLoss: 'No',
    unexplainedFever: 'No',
    everHadTb: 'Yes',
    hadTrippleVaccination: 'Yes',
    hadPolioVaccination: 'No',
    hadTetanus: 'No',
    hepatitisB: 'Yes',
    willYourRoleInvolveExposureProneProcedures: 'No',
    mrsaDate: '1970-01-01T00:00:00.000Z',
    cdiffDate: '1970-01-01T00:00:00.000Z',
    mrsaAndCDiffDetails: '',
    chickenPoxDate: '1970-01-01T00:00:00.000Z',
    bcgVaccineDate: '1970-01-01T00:00:00.000Z',
    tbDetails: 'Had TB unfortunately.\n',
    trippleVaccinationDate: '1970-01-01T00:00:00.000Z',
    polioVaccinationDate: '1970-01-01T00:00:00.000Z',
    tetanusDate: '1970-01-01T00:00:00.000Z'
  },
  consent: {
    consentForReportsBeingAssessed: 'Yes',
    consentToOurOccupationalHealthAdvisorsSpeakingWithYou: 'Yes',
    consentToOurOccupationalHealthAdvisorsMakingRecommendations: 'Yes'
  },
  stage: 'complete',
  _id: '6526d9d5ffe513246c729c96',
  applicationId: '651afe4dc01ab742b620a3f8',
  __v: 0,
  feedback: [],
  iDeclareAllAnswersAreCorrect: 'Yes'
};

import './style.css';
import SEQOHS from './pages/application/SEQOHS';
import Candidates from './pages/candidates';
import Print from './pages/print';

function App() {
  useEffect(() => {
    // @ts-ignore
    store.dispatch(loadUser());
  }, []);

  const token = localStorage.getItem('accessToken');
  if (token) {
    backend.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />

          <Route
            path="/jobs"
            element={
              <PrivateRoute>
                <Jobs />
              </PrivateRoute>
            }
          />

          <Route
            path="/invitations"
            element={
              <PrivateRoute>
                <Invitations />
              </PrivateRoute>
            }
          />

          <Route
            path="/candidates"
            element={
              <PrivateRoute>
                <Candidates />
              </PrivateRoute>
            }
          />

          <Route
            path="/applications"
            element={
              <PrivateRoute>
                <Applications />
              </PrivateRoute>
            }
          />

          <Route
            path="/application/:id"
            element={
              <PrivateRoute>
                <Application />
              </PrivateRoute>
            }
          />
          
          <Route
            path="/application/print/:id"
            element={
              <PrivateRoute>
                <Print />
              </PrivateRoute>
            }
          />

          <Route
            path="/statistics"
            element={
              <PrivateRoute>
                <UnderDevelopment title="This section is being developed." />
              </PrivateRoute>
            }
          />

          <Route
            path="/analytics"
            element={
              <PrivateRoute>
                <UnderDevelopment title="This section is being developed." />
              </PrivateRoute>
            }
          />

          <Route
            path="/pulseai"
            element={
              <PrivateRoute>
                <UnderDevelopment title="This section is being developed." />
              </PrivateRoute>
            }
          />

          <Route
            path="/seqohs"
            element={
              <PrivateRoute>
                <Box p={12}>
                  <SEQOHS data={data} />
                </Box>
              </PrivateRoute>
            }
          />
          <Route path="/" element={<Navigate to={'/dashboard'} />} />
        </Routes>
        <ToastContainer theme={'colored'} />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
