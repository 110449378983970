import React, { useCallback, useMemo, useState } from 'react';
import {
  Typography,
  Button,
  Grid,
  Box,
  Step,
  StepLabel,
  Stepper,
  Switch,
  TextField
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { CancelOutlined, SaveOutlined } from '@mui/icons-material';
// import NewJob from './NewJob';
import DialogModal from '../../components/Modal';
// import { createUser } from '../../api/ajax';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';
import NewJobModal from './NewJobDialog';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

const validate = (values) => {
  const errors: any = {};
  if (!values.title) {
    errors.title = 'Job title is required';
  } else if (values.title.length > 24) {
    errors.title = 'Must be 24 characters or less';
  }

  if (!values.overview) {
    errors.overview = 'Job Overview is required';
  } else if (values.overview.split(' ').length < 32) {
    errors.overview = 'Job Overview must be atleast 32 words';
  }
  return errors;
};

const steps = ['Basic', 'Eligibility', 'Attachments'];

const attachments = [
  {
    id: 1,
    title: 'Medical Form A',
    description: 'This is a form issues by your doctor'
  },
  {
    id: 2,
    title: 'Police Clearance',
    description: 'This is a form issues by your local police station'
  },
  {
    id: 3,
    title: 'Income Certificate',
    description: 'This is a form issues by your city revenue department'
  }
];

function PageHeader() {
  const [openNewJobModal, setOpenNewJobModal] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      title: '',
      overview: '',
      description: '',
      location: '',
      experience: '',
      salary: ''
    },
    validate,
    onSubmit: (values) => {
      handleNext(values);
    }
  });

  const handleSubmit = useCallback(() => {
    // setOpen(false);
    // createUser({})
    console.log(formik);
  }, []);

  const isStepOptional = (step: number) => {
    return step === 3;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = (values) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const username = useSelector(
    (state: RootState) => state.authReducer.user.firstName
  );

  const user = {
    name: username,
    avatar: '/static/images/avatars/1.jpg'
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Jobs
        </Typography>
        <Typography variant="subtitle2">
          {user.name}, these are the recent job posts you created
        </Typography>
      </Grid>
      <Grid item>
        <Button
          sx={{ mt: { xs: 2, md: 0 } }}
          variant="contained"
          startIcon={<AddTwoToneIcon fontSize="small" />}
          onClick={() => setOpenNewJobModal(true)}
        >
          New Job
        </Button>
      </Grid>
      <NewJobModal open={openNewJobModal} setOpen={setOpenNewJobModal} />
    </Grid>
  );
}

export default PageHeader;
