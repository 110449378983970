import {
  AUTH_FAIL,
  AUTH_SUCCESS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('accessToken'),
  loggedIn: false,
  loading: true,
  user: {}
};

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('accessToken', payload);
      return {
        ...state,
        token: localStorage.getItem('accessToken'),
        loggedIn: true,
        loading: false,
        user: payload
      };

    case LOGIN_FAIL:
    case AUTH_FAIL:
    case LOGOUT:
      localStorage.removeItem('accessToken');
      return {
        ...state,
        loggedIn: false,
        loading: false,
        user: null
      };

    case AUTH_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loading: false,
        user: payload.user
      };

    default:
      return state;
  }
};

export default authReducer;
