import React, { useCallback, useMemo, useState } from 'react';
import {
  Typography,
  Button,
  Grid,
  Box,
  Step,
  StepLabel,
  Stepper,
  Switch,
  TextField
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';
import NewJobModal from './NewJobDialog';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

function PageHeader() {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Candidates
        </Typography>
        <Typography variant="subtitle2">
          All your candidates in a tabular form
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
