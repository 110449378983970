import { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  Box,
  DialogActions,
  Tooltip,
  DialogContentText,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import {
  AddBox,
  AddBoxOutlined,
  EmailOutlined,
  Link,
  PersonPinCircleOutlined,
  PlusOne,
  PrintOutlined,
  RemoveCircleOutline,
  RemoveOutlined
} from '@mui/icons-material';
import Loading from 'src/components/Loading';
import { FieldArray, Form, Formik } from 'formik';
import { APPLICATION_SUB_FORMS } from 'src/constants';
import { backend } from 'src/services/http';
import { renderHttpErrors } from 'src/utils';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { useNavigate } from 'react-router';

const submitCorrection = async ({ feedback }, applicationId) => {
  try {
    const data = await backend.post('/applications/correction', {
      feedback,
      applicationId
    });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const notifyReferences = async (applicationId, referenceId?) => {
  try {
    await backend.post('/applications/notify-references', {
      applicationId,
      referenceId
    });
    return true;
  } catch (error) {
    renderHttpErrors(error);
    return false;
  }
};

const getReferenceLog = async (applicationId) => {
  try {
    const res: any = await backend.get(
      '/applications/reference-verification-log/' + applicationId
    );
    return res?.data?.data?.refLogs || [];
  } catch (error) {
    renderHttpErrors(error);
    return [];
  }
};

const formFeedbackFields = {
  form: '',
  comments: ''
};

function PageHeader({ applicationForm, loading }) {
  const navigate = useNavigate();
  const { application, professionalReferences } = applicationForm;
  const applicationFormsList = APPLICATION_SUB_FORMS;
  const [feedbackFormOpen, setFeedbackFormOpen] = useState(false);
  const [referenceLogs, setReferenceLogs] = useState([]);
  const [referenceFormDialogOpen, setReferenceFormFeedback] = useState(false);
  const [logDialogOpen, setLogDialogOpen] = useState({
    correctionModal: false,
    referenceModal: false
  });
  const [notifiedReference, setNotifiedReference] = useState();

  const handleSingleReferenceClick = (referenceId) => {
    if (notifyReferences(application._id, referenceId)) {
      setReferenceFormFeedback(false);
      toast('Email notification sent to one reference', {
        type: 'success'
      });
      setNotifiedReference(referenceId);
    }
  };

  const printApplication = (id) => {
    navigate('/application/print/' + id)
  }

  useEffect(() => {
    application?._id &&
      getReferenceLog(application?._id).then((res) => setReferenceLogs(res));
  }, [application?._id]);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {application?.job?.title}
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip
            title={
              application?.stage !== 'complete' ? 'Application Incomplete' : ''
            }
          >
            <Box display={'flex'} gap={2}>
              <Button
                onClick={() => printApplication(application._id)}
                sx={{ mt: { xs: 2, md: 0 } }}
                variant="contained"
                startIcon={<PrintOutlined fontSize="small" />}
                color="primary"
                disabled={application?.stage !== 'complete' ? true : false}
              >
                Print Application
              </Button>
              <Button
                onClick={() => setReferenceFormFeedback(true)}
                sx={{ mt: { xs: 2, md: 0 } }}
                variant="contained"
                startIcon={<EmailOutlined fontSize="small" />}
                color="primary"
                disabled={application?.stage !== 'complete' ? true : false}
              >
                Initiate Reference Feedback
              </Button>
              <Button
                onClick={() => setFeedbackFormOpen(true)}
                sx={{ mt: { xs: 2, md: 0 } }}
                variant="contained"
                startIcon={<PersonPinCircleOutlined fontSize="small" />}
                color="error"
                disabled={application?.stage !== 'complete' ? true : false}
              >
                Mark Correction
              </Button>
            </Box>
          </Tooltip>
        </Grid>
      </Grid>
      <Dialog
        open={feedbackFormOpen}
        maxWidth="sm"
        onClose={() => setFeedbackFormOpen(false)}
      >
        <Formik
          initialValues={{
            feedback: [formFeedbackFields]
          }}
          onSubmit={async (values) => {
            if (await submitCorrection(values, application._id)) {
              setFeedbackFormOpen(false);
              toast('Feedback / Correction has been recorded', {
                type: 'success'
              });
            }
          }}
        >
          {({ values, touched, errors, handleChange, handleBlur, isValid }) => (
            <Form style={{ width: '100%' }}>
              <DialogTitle>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography>Correction</Typography>
                  {/* <Button size="small" variant="contained">
                    Show Log
                  </Button> */}
                </Box>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography>
                      Marking a correction will unlock the certain parts of the
                      application for the candidate.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray name="feedback">
                      {({ push, remove }) =>
                        values.feedback.map((feedback, index) => {
                          return (
                            <>
                              <Box p={2} bgcolor={'#f1f1f1'} borderRadius={1}>
                                <Grid item xs={12}>
                                  <Box
                                    width={'100%'}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap={2}
                                  >
                                    <TextField
                                      size="small"
                                      fullWidth
                                      name={`feedback[${index}].form`}
                                      value={feedback.form}
                                      onChange={handleChange}
                                      select
                                      placeholder="Choose Form"
                                      label="Select a Form"
                                    >
                                      {applicationFormsList.map((form, idx) => (
                                        <MenuItem key={idx + 1} value={form}>
                                          {form}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                    <TextField
                                      multiline
                                      minRows={2}
                                      fullWidth
                                      name={`feedback[${index}].comments`}
                                      value={feedback.comments}
                                      onChange={handleChange}
                                      placeholder="Enter your feedback"
                                    ></TextField>
                                  </Box>
                                </Grid>
                              </Box>
                              <Box
                                py={1}
                                mb={3}
                                display="flex"
                                justifyContent={
                                  index === values.feedback.length - 1
                                    ? 'space-between'
                                    : 'flex-end'
                                }
                              >
                                <Button
                                  sx={{
                                    display:
                                      index === values.feedback.length - 1
                                        ? 'flex'
                                        : 'none'
                                  }}
                                  startIcon={<AddBoxOutlined />}
                                  variant="contained"
                                  size="small"
                                  onClick={() => push(formFeedbackFields)}
                                >
                                  Add more
                                </Button>
                                <Button
                                  sx={{
                                    display: index === 0 ? 'none' : 'flex'
                                  }}
                                  startIcon={<RemoveCircleOutline />}
                                  color="error"
                                  variant="contained"
                                  size="small"
                                  onClick={() => remove(index)}
                                >
                                  Delete
                                </Button>
                              </Box>
                            </>
                          );
                        })
                      }
                    </FieldArray>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ padding: 2 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="error"
                >
                  Submit Feedback
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
      <Dialog
        open={referenceFormDialogOpen}
        maxWidth="sm"
        onClose={() => setReferenceFormFeedback(false)}
      >
        <DialogTitle display={'flex'} justifyContent={'space-between'} id="">
          <Typography>Reference Verification</Typography>
          <Button
            onClick={() =>
              setLogDialogOpen({ ...logDialogOpen, referenceModal: true })
            }
            size="small"
            variant="outlined"
            color="primary"
          >
            View Log
          </Button>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="">
            <Typography mb={2}>
              Do you want to notify the references to verify the applicant?
            </Typography>
            <Table size="small">
              <TableBody>
                {professionalReferences?.references?.map((reference) => (
                  <TableRow>
                    <TableCell>{reference.fullName}</TableCell>
                    <TableCell>{reference.email}</TableCell>
                    <TableCell align="right">
                      <Button
                        onClick={() =>
                          handleSingleReferenceClick(reference._id)
                        }
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={notifiedReference === reference._id}
                      >
                        {notifiedReference === reference._id
                          ? 'Notified'
                          : 'Request Feedback'}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingX: 3 }}>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => setReferenceFormFeedback(false)}
          >
            Cancel
          </Button>
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={async () => {
              if (await notifyReferences(application._id)) {
                setReferenceFormFeedback(false);
                toast('Email notification sent to all references', {
                  type: 'success'
                });
              }
            }}
            autoFocus
          >
            Send Request to all
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reference Log Dialog */}
      <Dialog
        open={logDialogOpen.referenceModal}
        maxWidth="sm"
        onClose={() =>
          setLogDialogOpen({ ...logDialogOpen, referenceModal: false })
        }
      >
        <DialogTitle display={'flex'} justifyContent={'space-between'} id="">
          <Typography>Reference Notification Log</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="">
            <Typography mb={2}>
              Here is the log of the verification request emails you sent to the
              references of this applicant
            </Typography>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Reference Name</TableCell>
                  <TableCell>Email Email</TableCell>
                  <TableCell>Emailed At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {referenceLogs.length > 0 ? (
                  referenceLogs.map((refLog) => (
                    <TableRow key={refLog._id}>
                      <TableCell>{refLog?.name}</TableCell>
                      <TableCell>{refLog?.email}</TableCell>
                      <TableCell>
                        {format(
                          new Date(refLog?.createdAt),
                          'dd/MM/yyyy HH:mm:ss'
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <Typography p={2}>No log found...</Typography>
                )}
              </TableBody>
            </Table>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingX: 4 }}>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={() =>
              setLogDialogOpen({ ...logDialogOpen, referenceModal: false })
            }
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PageHeader;
